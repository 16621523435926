import React, { useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  callApi,
  callApiWithOptions,
  HttpMethod,
} from "src/services/apiService";
import Loading from "src/components/Loading";
import DisplayRecord from "./DisplayRecord";
import InfoModal from "src/components/InfoModal";
import { useNavigate } from "react-router-dom";
import IDisplayRecords from "src/interfaces/LogoCall/IDisplayRecords";
import PhoneNumbers from "./PhoneNumbers";
import { formatPhoneNumber } from "src/utils/formatUtils";

// Constants
const ENTERPRISE_REQUIRED_MESSAGE =
  "You must create an Enterprise before you can create Display Records. Please go to the Enterprise page to create one.";

const DisplayRecords: React.FC = () => {
  // State
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [records, setRecords] = useState<IDisplayRecords[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<IDisplayRecords>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enterpriseId, setEnterpriseId] = useState<string>("");
  const [hasEnterprise, setHasEnterprise] = useState(false);
  const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);
  const [isPhoneNumbersOpen, setIsPhoneNumbersOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState<string | null>(null);

  const navigate = useNavigate();

  // Handlers
  const handleGoToEnterprise = () => navigate("/logocall/enterprise");

  const handleAddClick = () => {
    if (!hasEnterprise) {
      setError(ENTERPRISE_REQUIRED_MESSAGE);
      return;
    }
    setSelectedRecord(undefined);
    setIsModalOpen(true);
  };

  const handleEditDIRClick = (record: IDisplayRecords) => {
    setSelectedRecord(record);
    setIsModalOpen(true);
  };

  const handleDeleteDIRClick = (record: IDisplayRecords) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      callApiWithOptions({
        endpoint: `bcid/dir/${record.displayIdentityRecordId}`,
        method: HttpMethod.DELETE,
      })
        .then(() => {
          loadData();
        })
        .catch((err) => {
          console.error(err);
          setError("An error occurred while deleting the record.");
        });
    }
  };

  const loadData = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Check if enterprise exists
      const response = await callApiWithOptions({
        endpoint: "bcid/enterprise",
        method: HttpMethod.GET,
        allowedErrorStatus: [404],
      });

      if (response.status === 404) {
        setHasEnterprise(false);
        setShowEnterpriseModal(true);
        return;
      }

      setHasEnterprise(true);

      // Load display records
      const data = await callApi("bcid/dir", HttpMethod.GET);
      setRecords(data);
      setEnterpriseId(data[0]?.enterpriseId || "");
    } catch (err) {
      const message =
        err instanceof Error
          ? err.message
          : "An error occurred while loading display records";
      setError(message);
      console.error("Error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhoneNumbersClick = (record: IDisplayRecords) => {
    setSelectedRecord(record);
    setSelectedRecordId(record.displayIdentityRecordId);
    setIsPhoneNumbersOpen(true);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      Display Records allow you to manage your branded calling information. Each
      record contains:
      <ul>
        <li>A Calling Name that will be shown to call recipients</li>
        <li>A Calling Logo that will be displayed during incoming calls</li>
        <li>Phone Numbers that are branded with this display information</li>
      </ul>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <button className="btn btn-primary mb-3" onClick={handleAddClick}>
        Add Display Record
      </button>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid
          data={records}
          style={{ height: "400px", maxWidth: "1000px" }}
          reorderable={true}
        >
          <GridColumn field="rcdName" title="Calling Name" width="200px" />
          <GridColumn
            title="Branded Numbers"
            width="200px"
            cell={(props) => {
              const phoneNumbers: string[] =
                props.dataItem?.telephoneNumberSample || [];

              return (
                <td>
                  <div style={{ fontSize: "0.875rem" }}>
                    {phoneNumbers.length > 0 ? (
                      phoneNumbers.map((number, index) => (
                        <span key={index}>
                          {formatPhoneNumber(number)}
                          <br />
                        </span>
                      ))
                    ) : (
                      <span>No Numbers Available</span>
                    )}
                  </div>
                </td>
              );
            }}
          />

          <GridColumn
            field="logoUrl"
            title="Calling Logo"
            width="150px"
            cell={(props) => (
              <td className="text-center align-middle">
                {props.dataItem.logoUrl && (
                  <img
                    src={props.dataItem.logoUrl}
                    alt="Calling Logo"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                )}
              </td>
            )}
          />
          <GridColumn
            field="status"
            title="Vetting Status"
            width="150px"
            cell={(props) => (
              <td className="text-center align-middle">
                {props.dataItem.status === 1 ? (
                  <span
                    className="badge text-bg-primary rounded-pill p-2"
                    style={{
                      fontWeight: "normal",
                      fontSize: "0.795rem",
                      lineHeight: "1",
                    }}
                  >
                    Approved
                  </span>
                ) : (
                  <span
                    className="badge text-bg-danger rounded-pill p-2"
                    style={{
                      fontWeight: "normal",
                      fontSize: "0.795rem",
                      lineHeight: "1",
                    }}
                  >
                    Not Approved
                  </span>
                )}
              </td>
            )}
          />
          <GridColumn
            title="Total Numbers"
            width="135px"
            cell={(props) => (
              <td className="text-center align-middle">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePhoneNumbersClick(props.dataItem);
                  }}
                >
                  <span className="badge rounded-pill bg-light border border-primary text-dark me-2">
                    {props.dataItem?.phoneNumberCount || 0}
                  </span>
                  Edit
                </button>
              </td>
            )}
          />

          <GridColumn
            title=""
            cell={(props) => (
              <td className="text-center align-middle">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => handleEditDIRClick(props.dataItem)}
                >
                  Edit
                </button>
                <button
                  className="ms-1 btn btn-sm btn-danger"
                  onClick={() => handleDeleteDIRClick(props.dataItem)}
                >
                  Delete
                </button>
              </td>
            )}
          />
        </Grid>
      )}
      <DisplayRecord
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          loadData();
        }}
        displayRecordId={selectedRecord?.displayIdentityRecordId ?? null}
      />
      <PhoneNumbers
        isOpen={isPhoneNumbersOpen}
        onClose={() => {
          setIsPhoneNumbersOpen(false);
          loadData();
        }}
        displayRecordId={selectedRecordId}
        enterpriseId={enterpriseId}
        callingName={selectedRecord?.rcdName ?? ""}
      />
      <InfoModal
        isOpen={showEnterpriseModal}
        onClose={() => setShowEnterpriseModal(false)}
        title="Enterprise Required"
        message={ENTERPRISE_REQUIRED_MESSAGE}
        actionLabel="Go to Enterprise"
        onAction={handleGoToEnterprise}
        required={true}
      />
    </div>
  );
};

export default DisplayRecords;
