import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { callApi, HttpMethod } from "src/services/apiService";
import {
  IStats,
  IWeeklyPerformance,
  ITrend,
} from "src/interfaces/LogoCall/IReports";
import Loading from "src/components/Loading";
import { CHART_COLORS, PIE_CHART_COLORS } from "./ChartColors";
import styles from "./Reporting.module.css";
import StatCard from "./StatCard";

const Reporting: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState<IStats>({} as IStats);

  const [trendData, setTrendData] = useState<ITrend[]>([]);
  const [weeklyPerformanceData, setWeeklyPerformanceData] = useState<
    IWeeklyPerformance[]
  >([]);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    new Date(new Date().setMonth(new Date().getMonth() - 1)), // 1 month ago
    new Date(), // today
  ]);

  const [weeklyDateRange, setWeeklyDateRange] = useState<
    [Date | null, Date | null]
  >([
    new Date(new Date().setMonth(new Date().getMonth() - 1)),
    new Date(), // today
  ]);

  const [startDate, endDate] = dateRange;
  const [weeklyStartDate, weeklyEndDate] = weeklyDateRange;

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setIsLoading(true);
        const response = await callApi("bcid/reports", HttpMethod.GET);
        setStats(response);
      } catch (error) {
        console.error("Failed to fetch stats:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
  }, []);

  useEffect(() => {
    const fetchTrendData = async () => {
      if (!startDate || !endDate) return;
      try {
        const response = await callApi(
          `bcid/reports/trend?startDate=${Math.floor(
            startDate.getTime() / 1000,
          )}&endDate=${Math.floor(endDate.getTime() / 1000)}`,
          HttpMethod.GET,
        );
        setTrendData(response);
      } catch (error) {
        console.error("Failed to fetch trend data:", error);
      }
    };

    fetchTrendData();
  }, [startDate, endDate]);

  useEffect(() => {
    const fetchWeeklyPerformance = async () => {
      if (!weeklyStartDate || !weeklyEndDate) return;
      try {
        const response = await callApi(
          `bcid/reports/weekly-performance?startDate=${Math.floor(
            weeklyStartDate.getTime() / 1000,
          )}&endDate=${Math.floor(weeklyEndDate.getTime() / 1000)}`,
          HttpMethod.GET,
        );

        if (!response || response.length === 0) {
          console.warn("No data returned from API.");
          return;
        }

        const formattedData = response.map((entry: IWeeklyPerformance) => ({
          ...entry,
          originated: entry.originated ?? 0,
          branded: entry.branded ?? 0,
        }));

        setWeeklyPerformanceData(formattedData);
      } catch (error) {
        console.error("Failed to fetch weekly performance data:", error);
      }
    };

    fetchWeeklyPerformance();
  }, [weeklyStartDate, weeklyEndDate]);

  if (isLoading) return <Loading />;

  const pieChartData = [
    { name: "Originated Calls", value: stats.totalBrandedRecords },
    {
      name: "Branded calls Delivered to Handset",
      value: stats.totalNumbersBranded,
    },
  ];

  return (
    <div>
      <div className="mb-4">
        <p className="text-muted">
          Gain key insights into your branded calling performance with real-time
          metrics and trends. Track originated calls, branded claims, and
          handset deliveries over time to optimize your caller deliverability.
        </p>
      </div>

      <div className="row g-4 mb-4">
        <StatCard
          title="Branded Calls Delivered to Handset Last 30 Days"
          current={stats.brandedCallsDelivered}
          previous={stats.brandedCallsDeliveredPrev}
        />
        <StatCard
          title="Originated Branded Calls Last 30 Days"
          current={stats.signedCalls}
          previous={stats.signedCallsPrev}
        />
        <StatCard
          title="Current Branded Numbers"
          current={stats.totalNumbersBranded}
          previous={null}
        />
        <StatCard
          title="Current Branded Records"
          current={stats.totalBrandedRecords}
          previous={null}
        />
      </div>

      {/* Charts Section */}
      <div className="row g-4">
        {/* Line Chart */}
        <div className="col-md-8">
          <div className="card border-0 shadow-sm">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="mb-0">Branded Calls Trend</h5>
                <div className="d-flex align-items-center">
                  <div className={styles.datePickerWrapper}>
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      className={`form-control ${styles.datePicker}`}
                      placeholderText="Select date range"
                      dateFormat="MMM d, yyyy"
                    />
                    <i className={`bi bi-calendar3 ${styles.calendarIcon}`}></i>
                  </div>
                </div>
              </div>
              <div style={{ height: "400px" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={trendData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="calls"
                      stroke={CHART_COLORS.PRIMARY}
                      strokeWidth={2}
                      dot={{ r: 4 }}
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        {/* Pie Chart */}
        <div className="col-md-4">
          <div className="card border-0 shadow-sm">
            <div className="card-body">
              <h5 className="mb-4">Branded Claims Distribution</h5>
              <div style={{ height: "400px" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={pieChartData}
                      cx="50%"
                      cy="50%"
                      innerRadius={80}
                      outerRadius={120}
                      fill={CHART_COLORS.PRIMARY}
                      paddingAngle={5}
                      dataKey="value"
                      label
                    >
                      {pieChartData.map((_, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={
                            PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]
                          }
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        {/* Bar Chart */}
        <div className="col-12">
          <div className="card border-0 shadow-sm">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="mb-0">Weekly Performance Comparison</h5>
                <div className="d-flex align-items-center">
                  <div className={styles.datePickerWrapper}>
                    <DatePicker
                      selectsRange={true}
                      startDate={weeklyStartDate}
                      endDate={weeklyEndDate}
                      onChange={(update) => {
                        if (update) {
                          setWeeklyDateRange(
                            update as [Date | null, Date | null],
                          );
                        }
                      }}
                      className={`form-control ${styles.datePicker}`}
                      placeholderText="Select date range"
                      dateFormat="MMM d, yyyy"
                    />
                    <i className={`bi bi-calendar3 ${styles.calendarIcon}`}></i>
                  </div>
                </div>
              </div>
              <div style={{ height: "400px" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={weeklyPerformanceData}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      angle={-15}
                      textAnchor="end"
                      height={60}
                      interval={0}
                    />
                    <YAxis domain={[0, "auto"]} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="originated" fill={CHART_COLORS.PRIMARY} />
                    <Bar dataKey="branded" fill={CHART_COLORS.SUCCESS} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reporting;
