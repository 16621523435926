import styles from "./Reporting.module.css";

export const CHART_COLORS = {
  PRIMARY: styles.colorPrimary,
  SUCCESS: styles.colorSuccess,
  WARNING: styles.colorWarning,
  DANGER: styles.colorDanger,
} as const;

export const PIE_CHART_COLORS = [
  CHART_COLORS.PRIMARY,
  CHART_COLORS.DANGER,
  CHART_COLORS.WARNING,
] as const;
