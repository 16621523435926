//import CryptoJS from "crypto-js";
import { isEncrypted } from "../utils/encryptionUtils";

//Obfuscating the data stored in the client's local storage so that it is not easily accessible to prying eyes.
//Anyone who is bent on cracking the system can do so, but this will at least make it more difficult.
type StorageKey = string;
//const secretKey: string =
//  import.meta.env.VITE_APP_STORAGE_ENCRYPTION_KEY ||
//  "A454c58a5034884a75f9070b1Bfdf05960ea8087bd03879H5abd47ce7b03ce935c9I";

// Disable encryption of local storage so we don't get
// false sense of security
// Note encryption done in encryptor.ts too
const encryptionEnabled = false;

const storageService = {
  setItem(key: StorageKey, value: any): void {
    try {
      const serializedValue = JSON.stringify(value);
      let valueToStore;

      if (encryptionEnabled) {
        /*valueToStore = CryptoJS.AES.encrypt(
          serializedValue,
          secretKey,
        ).toString();*/
        throw new Error(
          "CryptoJS removed to reduce bundle size as encryption disabled right now",
        );
      } else {
        valueToStore = serializedValue;
      }

      localStorage.setItem(key, valueToStore);
    } catch (error) {
      console.error("Error saving to localStorage", error);
    }
  },

  getItem<T>(key: StorageKey): T | null {
    try {
      const storedValue = localStorage.getItem(key);
      if (!storedValue) return null;

      let decryptedValue;

      // Decrypt the value if encryption is enabled OR the value is encrypted.
      // The OR is needed or users with saved data in Local Storage from when
      // encryption was enabled will ger error
      if (encryptionEnabled) {
        //const bytes = CryptoJS.AES.decrypt(storedValue, secretKey);
        //decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
        throw new Error(
          "CryptoJS removed to reduce bundle size as encryption disabled right now",
        );
      } else {
        decryptedValue = storedValue;
      }

      return decryptedValue ? (JSON.parse(decryptedValue) as T) : null;
    } catch (error) {
      console.error("Error reading from localStorage", error);
      return null;
    }
  },

  removeItem(key: StorageKey): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error("Error removing from localStorage", error);
    }
  },
};

export default storageService;
