import { CHART_COLORS } from "./ChartColors";

interface StatCardProps {
  title: string;
  current: number;
  previous: number | null;
}

const StatCard: React.FC<StatCardProps> = ({ title, current, previous }) => {
  const getPercentageChange = (current: number, previous: number | null) => {
    if (previous === null || previous === 0)
      return { value: "N/A", className: "text-muted", arrow: "" };

    const change = ((current - previous) / previous) * 100;
    const isPositive = change >= 0;

    return {
      value: `${Math.abs(change).toFixed(2)}%`,
      className: isPositive ? "text-success" : "text-danger",
      bgClassName: isPositive ? "bg-success-subtle" : "bg-danger-subtle",
      arrow: isPositive ? "↑" : "↓",
    };
  };

  const change = getPercentageChange(current, previous);

  return (
    <div className="col-md-3">
      <div className="card h-100 border-0 shadow-sm">
        <div className="card-body">
          <div className="d-flex flex-column">
            <div className="text-muted small">{title}</div>
            <div className="d-flex align-items-center mt-2">
              <h3 className="mb-0 me-2" style={{ color: CHART_COLORS.PRIMARY }}>
                {current.toLocaleString()}
              </h3>
              <span
                className={`badge ${change.bgClassName} ${change.className}`}
              >
                {previous !== null && (
                  <>
                    {change.arrow} {change.value}
                  </>
                )}
              </span>
            </div>
            <div className="text-muted small mt-2">
              {previous !== null && <>from {previous.toLocaleString()}</>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatCard;
