import { useState, useEffect } from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import Warning from "src/components/Warning";
import AppVerification from "src/pages/MFA/AppVerification";
import IMfaSettings from "src/interfaces/IMfaSettings";
import { formatPhoneNumber } from "src/utils/formatUtils";
import Loading from "src/components/Loading";

type SetupMainProps = {
  loginId: string;
  onCompleteSetup: () => void;
};

const SetupMain: React.FC<SetupMainProps> = ({ loginId, onCompleteSetup }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [authMethod, setAuthMethod] = useState<"email" | "phone" | "app">(
    "email",
  );
  const [code, setCode] = useState("");
  const [data, setData] = useState<IMfaSettings>();
  const [isLoading, setIsLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [phoneConsent, setPhoneConsent] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  async function fetchSettings() {
    try {
      const apiUrl = `mfa`;
      const response = await callApi(
        apiUrl,
        HttpMethod.GET,
        undefined,
        true,
        undefined,
        undefined,
        loginId,
      );
      setData(response);
      setShowEmail((response.mfaOptions & 1) !== 0);
      setShowPhone((response.mfaOptions & 2) !== 0);
      setShowApp((response.mfaOptions & 4) !== 0);
      setWarningMessage("");
    } catch (error) {
      console.error("Failed to fetch MFA settings:", error);
      setWarningMessage(
        "There was an issue processing the request. Please try again.",
      );
    }
  }

  const handleNext = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setIsLoading(true);
    switch (currentStep) {
      case 1:
        switch (authMethod.toLowerCase()) {
          case "email":
            sendToEmail();
            break;
          case "phone":
            sendToPhone();
            break;
          case "app":
            setCurrentStep(2);
            break;

          default:
            break;
        }

        break;
      case 2:
        if (authMethod === "app") {
          setCurrentStep(3);
        } else {
          verifyCode();
        }
        break;
      case 3:
        onCompleteSetup();
        break;
      default:
        break;
    }
    setIsLoading(false);
  };

  const sendToEmail = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `mfa/SendEmailVerification`;
      const response = await callApi(
        apiUrl,
        HttpMethod.POST,
        undefined,
        true,
        undefined,
        undefined,
        loginId,
      );
      if (response && response.type.toLowerCase() === "success") {
        // Update the UI to show the next step
        if (currentStep < 3) setCurrentStep(currentStep + 1);
      } else if (response && response.type.toLowerCase() === "error") {
        alert(response.error);
      } else {
        alert("Failed to send email verification code.");
      }
    } catch (error) {
      alert(`Failed to verify Email. Please try again.`);
    }
    setIsLoading(false);
  };

  const sendToPhone = async () => {
    try {
      const apiUrl = `mfa/SendPhoneVerification`;
      const response = await callApi(
        apiUrl,
        HttpMethod.POST,
        undefined,
        true,
        undefined,
        undefined,
        loginId,
      );
      if (response && response.type.toLowerCase() === "success") {
        // Update the UI to show the next step
        if (currentStep < 3) setCurrentStep(currentStep + 1);
      } else if (response && response.type.toLowerCase() === "error") {
        alert(response.error);
      }
    } catch (error) {
      alert(`Failed to verify SMS. Please try again.`);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const verifyCode = async () => {
    setIsLoading(true);
    let apiUrl = "";
    let requestBody = null;
    switch (authMethod.toLowerCase()) {
      case "email":
        apiUrl = `mfa/VerifyCode`;
        requestBody = {
          loginId: "",
          type: "email",
          code: code,
          deviceInfo: "",
          deviceId: "",
          trustDevice: false,
        };
        break;
      case "phone":
        apiUrl = `mfa/VerifyCode`;
        requestBody = {
          loginId: "",
          type: "phone",
          code: code,
          deviceInfo: "",
          deviceId: "",
          trustDevice: false,
        };
        break;
      case "changeemail":
        apiUrl = `mfa/ChangeEmail?email=${code}`;
        break;
      case "changephone":
        apiUrl = `mfa/ChangePhone?phone=${code}`;
        break;
      default:
      //Update error message here
    }
    if (apiUrl != "") {
      try {
        const response = await callApi(
          apiUrl,
          HttpMethod.POST,
          requestBody,
          true,
          undefined,
          undefined,
          loginId,
        );
        if (response && response.type.toLowerCase() === "success") {
          if (currentStep < 3) setCurrentStep(currentStep + 1);
        } else if (response && response.type.toLowerCase() === "failed") {
          setWarningMessage(response.message);
        }
      } catch (error) {
        console.error(`Failed to verify ${authMethod}:`, error);
      }
    }
    setIsLoading(false);
  };

  const resendCode = async () => {
    try {
      const apiUrl = `mfa/ResendVerificationCode?loginId=${loginId}&type=${authMethod}`;
      const response = await callApi(apiUrl, HttpMethod.POST);
      if (response && response.type.toLowerCase() === "success") {
        setWarningMessage(`Verification code has been resent.`);
      } else {
        setWarningMessage(
          "There was an issue sending the code. Please try again.",
        );
      }
    } catch (error) {
      setWarningMessage(
        "UThere was an issue sending the code. Please try again.",
      );
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <h5>Set up multi-factor authentication</h5>
      <div>
        {currentStep === 1 && (
          <>
            <h6 className="mb-3">Choose an authentication method</h6>
            <p>
              In addition to your username and password, you'll have to enter a
              code to sign in to your account.
            </p>
            <div className="form-group">
              {showEmail && (
                <div
                  className={`card mb-3 ${authMethod === "email" ? "border-primary" : ""}`}
                  onClick={() => setAuthMethod("email")}
                >
                  <div className="card-body d-flex align-items-center">
                    <div className="row w-100">
                      <div className="col-2 pt-2">
                        <input
                          type="radio"
                          value="email"
                          checked={authMethod === "email"}
                          onChange={() => setAuthMethod("email")}
                        />
                      </div>
                      <div className="col-10 text-start">
                        <strong>Email</strong>
                        <br />
                        Receive a unique code via Email to {data?.email}.
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showPhone && (
                <div
                  className={`card mb-3 ${authMethod === "phone" ? "border-primary" : ""}`}
                  onClick={() => setAuthMethod("phone")}
                >
                  <div className="card-body d-flex align-items-center">
                    <div className="row w-100">
                      <div className="col-2 pt-2">
                        <input
                          type="radio"
                          value="phone"
                          checked={authMethod === "phone"}
                          onChange={() => setAuthMethod("phone")}
                        />
                      </div>
                      <div className="col-10 text-start">
                        <strong>Text</strong>
                        <br />
                        Receive a unique code via SMS to{" "}
                        {data?.phone ? formatPhoneNumber(data.phone) : ""}.
                        <p className="p-3" style={{ width: "25em" }}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="phoneConsentCB"
                            checked={phoneConsent}
                            onChange={() => setPhoneConsent(!phoneConsent)}
                          ></input>
                          <label
                            htmlFor="phoneConsentCB"
                            className="d-inline ps-2"
                          >
                            <small>
                              I agree to receive SMS verification codes from
                              Contact Center Compliance. Message &amp; data
                              rates may apply.
                            </small>
                          </label>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showApp && (
                <div
                  className={`card mb-3 ${authMethod === "app" ? "border-primary" : ""}`}
                  onClick={() => setAuthMethod("app")}
                >
                  <div className="card-body d-flex align-items-center">
                    <div className="row w-100">
                      <div className="col-2 pt-2">
                        <input
                          type="radio"
                          value="app"
                          checked={authMethod === "app"}
                          onChange={() => setAuthMethod("app")}
                        />
                      </div>
                      <div className="col-10 text-start">
                        <strong>Authenticator App</strong>
                        <br />
                        Use an authenticator app on your phone to validate your
                        login.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {currentStep === 2 && (
          <>
            {authMethod === "app" ? (
              <AppVerification
                onClose={() => setCurrentStep(3)}
                loginId={loginId}
              />
            ) : (
              <>
                <h6 className="mb-3">
                  A code has been sent to your {authMethod}
                </h6>
                <p>
                  Enter the code you have received{" "}
                  {authMethod === "email" ? "email" : "phone"}.
                </p>
                <Warning message={warningMessage} />
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)} // Update the code state
                  />
                  <div className="mt-2">
                    Didn't receive a code?{" "}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        resendCode();
                      }}
                    >
                      Resend code
                    </a>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {currentStep === 3 && (
          <>
            <h6 className="mb-3">Setup complete</h6>
            <p>
              You have successfully set up multi-factor authentication using{" "}
              {authMethod}.
            </p>
          </>
        )}
        <div className="d-flex justify-content-between mt-4">
          <button
            className="btn btn-secondary"
            onClick={handleBack}
            disabled={currentStep === 1}
          >
            Back
          </button>
          <button
            className="btn btn-primary"
            onClick={handleNext}
            disabled={
              (currentStep === 1 && authMethod === "phone" && !phoneConsent) ||
              (currentStep === 2 && code.trim() === "")
            }
          >
            {currentStep === 3 ? "Done" : "Next"}
          </button>
        </div>
      </div>
    </>
  );
};

export default SetupMain;
