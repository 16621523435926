import React, { useEffect, useState, useCallback } from "react";
import { useDropzone, FileRejection } from "react-dropzone";
import { callApi, HttpMethod } from "src/services/apiService";
import Loading from "src/components/Loading";
import SaveInProgress from "src/components/SaveInProgress";
import FileUploadContainer from "src/components/FileUploadContainer";
import {
  IDisplayRecord,
  IDisplayIdentityRecord,
  ICallReason,
} from "src/interfaces/LogoCall/IDisplayIdentityRecord";

const resizeImage = (
  file: File,
  width: number,
  height: number,
): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target?.result) {
        return reject(new Error("Failed to read file."));
      }
      const img = new Image();
      img.src = e.target.result as string;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        if (!ctx) {
          return reject(new Error("Could not get canvas context"));
        }
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
              });
              resolve(resizedFile);
            } else {
              reject(new Error("Canvas is empty"));
            }
          },
          file.type,
          1,
        );
      };
      img.onerror = () => reject(new Error("Failed to load image"));
    };
    reader.onerror = () => reject(new Error("Failed to read file"));
    reader.readAsDataURL(file);
  });
};

interface DisplayRecordProps {
  isOpen: boolean;
  onClose: () => void;
  displayRecordId: string | null;
}

const DisplayRecordModal: React.FC<DisplayRecordProps> = ({
  isOpen,
  onClose,
  displayRecordId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [_data, setData] = useState<IDisplayRecord | null>();
  const [dir, setDir] = useState<IDisplayIdentityRecord>();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [callReasons, setCallReasons] = useState<ICallReason[]>([]);
  const [newCallReasonText, setNewCallReasonText] = useState("");
  const [editingCallReasonId, setEditingCallReasonId] = useState<number | null>(
    null,
  );
  const [editingCallReasonText, setEditingCallReasonText] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [callingName, setCallingName] = useState("");
  const [_logoFile, setLogoFile] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState("");

  const fetchDisplayRecordData = useCallback(async () => {
    if (!displayRecordId) {
      // Abhi: Reset the form as the user might have clicked edit and now come to Adding a new Display Record
      setData(null);
      setDir(undefined);
      setCallingName("");
      setLogoPreview("");
      setCallReasons([]);
      return;
    }
    setIsLoading(true);
    try {
      const apiUrl = `bcid/dir/${displayRecordId}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      setData(response);
      setDir(response.DisplayIdentityRecord);
      setCallingName(response.DisplayIdentityRecord.rcdName);
      setLogoPreview(response.DisplayIdentityRecord.logoUrl);
      setCallReasons(response.CallReasons || []);
    } catch (error) {
      setWarningMessage("Failed to fetch data.");
      console.error("Failed to fetch data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [displayRecordId]);

  useEffect(() => {
    fetchDisplayRecordData();
  }, [displayRecordId, fetchDisplayRecordData]);

  const fileToBase64 = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject(new Error("Conversion failed"));
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles[0]) {
      const file = acceptedFiles[0];
      try {
        const resizedFile = await resizeImage(file, 256, 256);
        setLogoFile(resizedFile);
        const base64 = await fileToBase64(resizedFile);
        setLogoPreview(base64);
        setFileErrorMessage("");
      } catch (error) {
        console.error("Error resizing image", error);
      }
    }
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      setFileErrorMessage("Please upload a valid image file (jpg, png, etc.)");
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: { "image/*": [] },
  });

  const handleAddCallReason = () => {
    if (!newCallReasonText.trim() || callReasons.length >= 10) return;
    setCallReasons([...callReasons, { CallReason: newCallReasonText }]);
    setNewCallReasonText("");
  };

  const handleEditCallReason = (index: number) => {
    setEditingCallReasonId(index);
    setEditingCallReasonText(callReasons[index].CallReason);
  };

  const handleSaveCallReason = (index: number) => {
    if (!editingCallReasonText.trim()) return;
    const updatedCallReasons = callReasons.map((reason, i) =>
      i === index ? { CallReason: editingCallReasonText } : reason,
    );
    setCallReasons(updatedCallReasons);
    setEditingCallReasonId(null);
    setEditingCallReasonText("");
  };

  const handleDeleteCallReason = (index: number) => {
    const updatedCallReasons = callReasons.filter((_, i) => i !== index);
    setCallReasons(updatedCallReasons);
  };

  const handleSave = async () => {
    const displayRecord: IDisplayIdentityRecord = {
      displayIdentityRecordId: dir?.displayIdentityRecordId || null,
      enterpriseId: dir?.enterpriseId || null,
      rcdName: callingName,
      logoUrl:
        logoPreview && logoPreview.startsWith("http")
          ? ""
          : (logoPreview ?? ""),
    };
    const payload: IDisplayRecord = {
      DisplayIdentityRecord: displayRecord,
      CallReasons: callReasons,
    };

    try {
      setSaveInProgress(true);
      const response = await callApi("bcid/dir/save", HttpMethod.POST, payload);
      console.log("Save successful", response);
      onClose();
    } catch (error) {
      alert("There was an issue saving the record. Please try again.");
      console.error("Error saving", error);
    } finally {
      setSaveInProgress(false);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="modal-backdrop show" />
      <div className="modal show d-block" tabIndex={-1}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {displayRecordId ? "Edit" : "Add"} Display Record
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                aria-label="Close modal"
              ></button>
            </div>
            <div className="modal-body">
              {isLoading && <Loading />}
              {warningMessage && (
                <div className="alert alert-warning">{warningMessage}</div>
              )}
              <div className="d-flex gap-4" style={{ minHeight: "750px" }}>
                <div className="flex-grow-1">
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Calling Name</b>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={callingName}
                      onChange={(e) => setCallingName(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <div className="row">
                      <div className="col-12">
                        <label className="form-label">
                          <b>Call Logo</b>
                        </label>
                      </div>
                    </div>
                    <div className="row align-items-start">
                      <div className="col">
                        <FileUploadContainer
                          getRootProps={getRootProps}
                          getInputProps={getInputProps}
                          isDragActive={isDragActive}
                          title="Upload Logo"
                          iconClassName="bi-upload"
                        />
                        {fileErrorMessage && (
                          <div className="text-danger mt-2">
                            {fileErrorMessage}
                          </div>
                        )}
                      </div>
                      <div className="col-auto">
                        <div
                          className="border"
                          style={{ width: "100px", height: "100px" }}
                        >
                          {logoPreview && (
                            <img
                              src={logoPreview}
                              alt="Logo Preview"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3" style={{ minHeight: "350px" }}>
                    <label className="form-label">
                      <b>Call Reason</b>
                      <small className="text-muted ps-2">(max 10)</small>
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Call Reasons e.g., 'Delivery Confirmation'"
                        value={newCallReasonText}
                        onChange={(e) => setNewCallReasonText(e.target.value)}
                      />
                      <button
                        className="btn btn-secondary"
                        onClick={handleAddCallReason}
                        disabled={callReasons.length >= 10}
                      >
                        Add
                      </button>
                    </div>
                    <table className="table table-dark mt-3">
                      <thead>
                        <tr>
                          <th>Call Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {callReasons.map((cr, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex justify-content-between align-items-center">
                                {editingCallReasonId === index ? (
                                  <input
                                    type="text"
                                    value={editingCallReasonText}
                                    onChange={(e) =>
                                      setEditingCallReasonText(e.target.value)
                                    }
                                    className="form-control form-control-sm"
                                  />
                                ) : (
                                  <span>{cr.CallReason}</span>
                                )}
                                <div className="d-flex gap-2 align-items-end">
                                  {editingCallReasonId === index ? (
                                    <>
                                      <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() =>
                                          handleSaveCallReason(index)
                                        }
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="btn btn-sm btn-secondary"
                                        onClick={() => {
                                          setEditingCallReasonId(null);
                                          setEditingCallReasonText("");
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="btn btn-sm btn-link text-primary"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleEditCallReason(index);
                                        }}
                                      >
                                        Edit
                                      </button>
                                      <button
                                        className="btn btn-sm btn-link text-primary"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleDeleteCallReason(index);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div
                    className="mb-3 p-3"
                    style={{ backgroundColor: "#f8f9fa" }}
                  >
                    <div className="form-check d-flex align-items-start mb-0 text-muted">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="authorization"
                        checked={isAuthorized}
                        onChange={(e) => setIsAuthorized(e.target.checked)}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="authorization"
                      >
                        <small>
                          I authorize that I have authority to use the entered
                          Telephone Numbers, Calling Logo, and Call Reasons
                        </small>
                      </label>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                    disabled={!isAuthorized}
                  >
                    {!saveInProgress ? (
                      "Save Display Record"
                    ) : (
                      <SaveInProgress isVisible={saveInProgress} />
                    )}
                  </button>
                </div>

                <div className="p-2 d-flex flex-column">
                  <div
                    style={{
                      width: "350px",
                      height: "700px",
                      minHeight: "700px",
                      alignSelf: "center",
                      backgroundImage: "url('/images/incomingcall.jpg')",
                      backgroundSize: "cover",
                      backgroundPosition: "top center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="pt-5 pb-2 mb-2 text-white">&nbsp;</div>
                    <div className="pb-2 mb-2 text-white text-center">
                      {callingName}
                    </div>
                    <div style={{ height: "80px" }}>
                      {logoPreview && (
                        <img
                          src={logoPreview}
                          alt="Logo Preview"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisplayRecordModal;
