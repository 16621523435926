export interface IAccountSettings {
  acctInfo: AcctInfo;
  acctName: string;
  cpa: CPA;
  agentQSAuth: number;
  acctAuths: AcctAuth[];
  settings: Setting;
  checkboxes: Checkboxes;
  positiveValues: PositiveValues;
  agentRights: { [key: string]: boolean }; //AK: Dynamic field.  Data does not come from the Web API.
}

interface CPA {
  administer: string;
  agent: string;
  supervisor: string;
  project: string;
  subAccounts: string;
  ftp: string;
}

interface AcctInfo {
  accountStatus: number;
  maxScrubsPerMonth: string;
  maxPoliciesSentPerMonth: string | null;
  basePlanId: string;
  hasAccountFeatureTCPAAuthority: boolean;
  hasAccountFeatureTCPAAuthorityPlus: boolean;
  hasAccountFeatureBasicRND: boolean;
}

interface AcctAuth {
  AcctName: string;
  IsChecked: string;
}

interface Setting {
  autoPrefixProjIds: boolean;
  blockAfterScrubsReaches: number;
  blockAfterPoliciesSentReaches: number;
  warnAfterScrubsReaches: number;
  warnAfterPoliciesSentReaches: number;
  warningRecipients: string | null;
  eventRecipients: string | null;
  emailOnReassignedJobCompletion: string;
  zipPasswordEncrypted: string | null;
  zipPasswordOutput: boolean;
  autoUpgradeToMultiUser: number;
  subadminPasswordHash: string;
  ftpPasswordHash: string;
  agentHomepage: string;
  excludeMobileToMobilePorts: boolean;
  mfaRequired: boolean;
  mfaOptions: string;
}

interface Checkboxes {
  blockAfterScrubsReaches: boolean;
  blockAfterPoliciesSentReaches: boolean;
  warnAfterScrubsReaches: boolean;
  warnAfterPoliciesSentReaches: boolean;
  tcpaAuthorityAllowedCountThisMonth: boolean;
  tcpaAuthorityCountBeforeSendEmail: boolean;
  tcpaAuthorityPlusAllowedCountThisMonth: boolean;
  tcpaAuthorityPlusCountBeforeSendEmail: boolean;
  basicRNDAllowedCountThisMonth: boolean;
  basicRNDCountBeforeSendEmail: boolean;
}

interface PositiveValues {
  blockAfterScrubsReaches: number;
  blockAfterPoliciesSentReaches: number;
  warnAfterScrubsReaches: number;
  warnAfterPoliciesSentReaches: number;
  tcpaAuthorityAllowedCountThisMonth: number;
  tcpaAuthorityCountBeforeSendEmail: number;
  tcpaAuthorityPlusAllowedCountThisMonth: number;
  tcpaAuthorityPlusCountBeforeSendEmail: number;
  basicRNDAllowedCountThisMonth: number;
  basicRNDCountBeforeSendEmail: number;
  mfaRequired: boolean;
}

export enum MfaOptions {
  None = 0, // 0000
  Email = 1 << 0, // 0001
  SMS = 1 << 1, // 0010
  App = 1 << 2, // 0100
}
