import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MagnifyingGlass } from "react-loader-spinner";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";
import { callApi, HttpMethod } from "src/services/apiService";
import styles from "./InvestigatePhoneNumber.module.css";
import UserRoleIcon from "src/components/UserRoleIcon";
import Pagination from "src/components/Pagination";

type HistoryInfo = {
  campaignId: number;
  date: string;
  projId: string;
  roleId: number;
  sessionId: number;
  status: string;
  text: string;
  user: string;
};
type PhoneInfo = {
  city: string;
  country: string;
  initialCarrier: string;
  prefix: string;
  state: string;
  timezone: string;
};

const ITEMS_PER_PAGE = 25;

const InvestigatePhoneNumber = () => {
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isSubmitted },
  } = useForm();
  const { acctId } = useAppSelector(selectAuth);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [responsePhoneInfo, setResponsePhoneInfo] = useState<PhoneInfo | null>(
    null,
  );
  const [responseHistoryInfo, setResponseHistoryInfo] = useState<HistoryInfo[]>(
    [],
  );
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = useCallback(
    async (phoneNumber: string) => {
      const apiUrl = `main/NumberHistory?phone=${phoneNumber}&acctId=${acctId}`;
      return await callApi(apiUrl, HttpMethod.GET);
    },
    [acctId],
  );

  const formatPhoneNumber = useCallback((value: any) => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length <= 3) return `(${onlyNums}`;
    if (onlyNums.length <= 6)
      return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
      6,
      10,
    )}`;
  }, []);

  const formatDate = useCallback((dateString: string): string => {
    if (!dateString || isNaN(Date.parse(dateString + "Z"))) {
      return "";
    }

    var timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDate = new Date(dateString + "Z");

    return new Intl.DateTimeFormat("en-US", {
      timeZone, // Browser time zone
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    }).format(utcDate);
  }, []);

  const onSubmit = useCallback(
    async (data: any) => {
      setIsLoading(true);
      setPhoneNumber(data.phoneNumber);
      const phoneNumber = data.phoneNumber.replace(/[^\d]/g, "");
      try {
        const response = await fetchData(phoneNumber);

        if (response?.message) {
          setResponseMessage(response.message);
        } else {
          setResponseMessage("");
        }
        if (response?.phoneInfo) {
          setResponsePhoneInfo(response.phoneInfo);
        } else {
          setResponsePhoneInfo(null);
        }
        if (response?.history) {
          setResponseHistoryInfo(response.history);
        } else {
          setResponseHistoryInfo([]);
        }
        reset();
      } catch (error) {
        console.error("Error fetching Number History", error);
      } finally {
        setIsLoading(false);
      }
    },
    [reset, setValue],
  );

  const totalPages = Math.ceil(responseHistoryInfo.length / ITEMS_PER_PAGE);
  const currentHistory = responseHistoryInfo.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE,
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-fluid">
      <h1>Investigate Phone Number</h1>
      <div className="card mt-3">
        <div className="card-header">Investigate Phone Number</div>
        <div className="card-body">
          <p>
            This form checks the history of a single phone number in your
            account. Its primary purpose is to aid in DNC violation
            investigations. Note that this history does not provide information
            about what exemptions and settings may have been in place at the
            time of scrubbing. If your account has scrubbed many records, it may
            take several minutes for this page to find all instances when this
            number was scrubbed.
          </p>

          <p>
            We frequently update the DNC databases in our system. It is
            important to note that the date shown for when a phone number was
            added to a particular database is the date this number was part of a
            database update. It is NOT indicative of the true date that the
            consumer added themselves to the original database, which is not
            provided to us by the database supplier.
          </p>

          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Phone Number is required",
                    pattern: {
                      value: /^\(\d{3}\) \d{3}-\d{4}$/,
                      message:
                        "Phone Number must be 10 digits number and in (xxx) xxx-xxxx format",
                    },
                  }}
                  render={({ field }) => (
                    <input
                      {...field}
                      onChange={(e) =>
                        field.onChange(formatPhoneNumber(e.target.value))
                      }
                      className={`form-control ${
                        errors.phoneNumber ? "is-invalid" : ""
                      }`}
                      placeholder="Enter Phone Number"
                      type="text"
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <p>{errors.phoneNumber.message?.toString()}</p>
                )}
              </div>

              <button
                disabled={!isDirty || isLoading}
                type="submit"
                className="btn btn-primary"
              >
                Get History
              </button>
            </form>
          </div>
          {isLoading && (
            <div className="loader-container">
              <MagnifyingGlass
                visible={isLoading}
                height="80"
                width="80"
                ariaLabel="MagnifyingGlass-loading"
                wrapperStyle={{}}
                wrapperClass="MagnifyingGlass-wrapper"
                glassColor="#c0efff"
                color="#0d6efd"
              />
            </div>
          )}
          {responseMessage && !isLoading && (
            <div style={{ marginTop: 20 }}>
              <span>
                {responseMessage}
                <b style={{ marginLeft: 5 }}>{phoneNumber}</b>
              </span>
            </div>
          )}
        </div>
      </div>
      {responsePhoneInfo && !isLoading ? (
        <div className="card mt-3">
          <div className="card-header">
            {" "}
            Information for Area Code and Prefix
          </div>
          <div className="card-body">
            <table className="table table-bordered table-hover table-striped border-primary">
              <thead>
                <tr>
                  <th className="text-center">City</th>
                  <th className="text-center">State</th>
                  <th className="text-center">Country</th>
                  <th className="text-center">Timezone</th>
                  <th className="text-center">Initial Carrier</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{responsePhoneInfo.city}</td>
                  <td>{responsePhoneInfo.state}</td>
                  <td>{responsePhoneInfo.country}</td>
                  <td>{responsePhoneInfo.timezone}</td>
                  <td>{responsePhoneInfo.initialCarrier}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      {responsePhoneInfo?.prefix && !isLoading ? (
        <div className="card mt-3">
          <div className="card-header"> Prefix Databases</div>
          <div className="card-body">
            <b>{responsePhoneInfo.prefix}</b>
          </div>
        </div>
      ) : null}
      {responseHistoryInfo &&
      responseHistoryInfo.length > 0 &&
      !isLoading &&
      isSubmitted &&
      !responseMessage ? (
        <div className="card mt-3">
          <div className="card-header">
            Listed below are scrub history for phone # <b>{phoneNumber}</b>
          </div>
          <div className="card-body">
            <table className="table table-bordered table-hover table-striped border-primary">
              <thead className={`${styles["sticky-header"]}`}>
                <tr>
                  <th className="text-center">Event</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Project</th>
                  <th className="text-center">By User</th>
                </tr>
              </thead>
              <tbody>
                {currentHistory.map((item: HistoryInfo, index: number) => (
                  <tr key={index}>
                    <td>{item.text}</td>
                    <td>{formatDate(item.date)}</td>
                    <td>{item.projId}</td>
                    {item.user && (
                      <td style={{ backgroundColor: "inherit" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <UserRoleIcon Role={item.roleId} />
                          <span>{item.user}</span>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      ) : null}

      {responseHistoryInfo &&
      responseHistoryInfo.length === 0 &&
      !isLoading &&
      isSubmitted &&
      !responseMessage &&
      !errors ? (
        <div className="card mt-3">
          <div className="card-header">
            Listed below are scrub history for phone # <b>{phoneNumber}</b>
          </div>
          <div className="card-body">
            <span>No phone number history found.</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InvestigatePhoneNumber;
