import { useState, useEffect } from "react";
import { selectAuth } from "src/features/authSlice";
import { useAppSelector } from "src/app/hooks";
import styles from "./Paywall.module.css";
import { HttpMethod, callApi } from "src/services/apiService";

type PaywallProps = {
  isAdmin: boolean;
};

/**
 * Paywall component that prompts the user to subscribe to Compliance Guide.
 */
const Paywall: React.FC<PaywallProps> = ({ isAdmin }) => {
  const [showAddConfirmation, setShowModal] = useState(false);
  const [subscriptionAdded, setSubscriptionAdded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { fullName, username } = useAppSelector(selectAuth);

  // Monitor to see if the user has removed the paywall from
  // the DOM in devtools. If so, refresh screen so they can't
  // (easily) hack the paywall
  // Note the user could still get around this by removing the background-filter
  useEffect(() => {
    const paywallElement = document.getElementById("paywall");

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "childList") {
          const stillExists = !!document.getElementById("paywall");
          if (!stillExists && !subscriptionAdded) {
            // Refresh the page as the paywall was removed
            // using devtools
            window.location.reload();
          }
        }
      });
    });

    if (paywallElement && paywallElement.parentNode) {
      observer.observe(paywallElement.parentNode, {
        childList: true,
        subtree: false,
      });
    }

    return () => observer.disconnect();
  }, [subscriptionAdded]);

  const handleUnlockClick = () => {
    setShowModal(true);
    setErrorMessage(""); // Clear any previous errors
  };

  const handleConfirmSubscription = async () => {
    setIsProcessing(true);
    setErrorMessage("");

    try {
      const response = await callApi(
        "ComplianceGuide/AddComplianceGuide",
        HttpMethod.POST,
        "",
      );

      // Check if response exists first
      if (!response) {
        throw new Error("No response received from server");
      }

      // If we got a response, check its success property
      if (response.includes("Compliance Guide feature added successfully")) {
        setShowModal(false);
        setSubscriptionAdded(true);
      } else {
        // Only throw error if success is explicitly false
        throw new Error(
          response.message ||
            "Failed to add Compliance Guide subscription please contact support@dnc.com",
        );
      }
    } catch (error) {
      setErrorMessage(
        error instanceof Error
          ? error.message
          : "Unable to process your subscription at this time. Please try again later or contact support.",
      );
      console.error("Subscription error:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCancelSubscription = () => {
    setShowModal(false);
    setErrorMessage("");
  };

  return (
    <>
      {!subscriptionAdded && (
        <div id="paywall" className={styles["paywall-container"]}>
          <div className={styles["paywall-container-message"]}>
            {!showAddConfirmation && (
              <>
                <h1>Unlock Full Access to Compliance Guide</h1>
                <p>
                  Your account is not subscribed to Compliance Guide. Subscribe
                  to unlock powerful tools now.
                </p>
                {errorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                {!isAdmin ? (
                  <>
                    <button
                      className="btn btn-primary"
                      id="unlockButton"
                      onClick={handleUnlockClick}
                      disabled={isProcessing}
                    >
                      Add Compliance Guide Subscription for $1200 per year
                    </button>
                  </>
                ) : (
                  <p>
                    Contact your Account Administrator to add Compliance Guide.
                  </p>
                )}
                <div className={styles.videoContainer}>
                  <iframe
                    src="https://player.vimeo.com/video/882688433"
                    title="Compliance Guide Intro"
                    allowFullScreen
                  ></iframe>
                </div>
              </>
            )}
          </div>

          {showAddConfirmation && (
            <div
              className="modal d-block"
              tabIndex={-1}
              onClick={() => !isProcessing && setShowModal(false)}
            >
              <div
                className="modal-dialog"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Are you sure you want to add a Compliance Guide
                      subscription?
                    </h5>
                  </div>
                  <div className="modal-body d-flex">
                    <i className="h1 text-info bi bi-shield-check p-1"></i>
                    <div>
                      <p>
                        This action will add Compliance Guide to your account
                        for $1,200 per year. You will be billed on your next
                        invoice.
                      </p>
                      <p>
                        Do you want to proceed? By clicking Yes you authorize
                        that you have the authority to add Compliance Guide to
                        your account.
                      </p>
                      <p>
                        You are logged in as user{" "}
                        <b>
                          {fullName} ({username})
                        </b>
                        . By clicking Yes, you are authorizing this action.
                      </p>
                      {errorMessage && (
                        <div className="alert alert-danger mt-3" role="alert">
                          {errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleCancelSubscription}
                      disabled={isProcessing}
                    >
                      No, Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleConfirmSubscription}
                      disabled={isProcessing}
                    >
                      {isProcessing ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Processing...
                        </>
                      ) : (
                        "Yes, Add Subscription"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {subscriptionAdded && (
        <div>
          <h1>Thank you for subscribing!</h1>
          <p>
            You now have full access to the Compliance Guide. Enjoy the powerful
            tools to make compliance easy.
          </p>
        </div>
      )}
    </>
  );
};

export default Paywall;
