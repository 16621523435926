import { useEffect, useState } from "react";
import { HttpMethod, callApi } from "src/services/apiService";

const AppVerification = ({
  onClose,
  loginId,
}: {
  onClose: () => void;
  loginId?: string;
}) => {
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [authCode, setAuthCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchQrCode(); // Fetch QR code when the component loads
  }, []);

  const fetchQrCode = async () => {
    try {
      const apiUrl = `mfa/SendAppVerification`;

      // Call API to get the QR Code
      const response = await callApi(
        apiUrl,
        HttpMethod.POST,
        undefined,
        true,
        undefined,
        undefined,
        loginId,
      );

      if (response?.message) {
        setQrCode(`data:image/png;base64,${response.message}`);
      } else {
        setError("Failed to retrieve QR code.");
      }
    } catch (error) {
      console.error("Error fetching QR Code:", error);
      setError("Error retrieving QR Code. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async () => {
    if (!authCode) {
      setError("Please enter the authentication code.");
      return;
    }

    try {
      const apiUrl = `mfa/VerifyCode?type=app&code=${authCode}`;
      const response = await callApi(
        apiUrl,
        HttpMethod.POST,
        undefined,
        true,
        undefined,
        undefined,
        loginId,
      );

      if (response && response.type.toLowerCase() === "success") {
        alert("Verification successful!");
        onClose(); // Close the verification screen
      } else {
        setError(response?.message || "Verification failed.");
      }
    } catch (error) {
      console.error("Error verifying code:", error);
      setError("Failed to verify code. Please try again.");
    }
  };

  return (
    <div
      className="d-flex bg-dark bg-opacity-75"
      style={{ width: 750, zIndex: 1050 }}
    >
      {/* Left Side - QR Code */}
      <div className="col-6 d-flex align-items-center justify-content-center bg-white">
        <div className="text-center">
          <h5>Setup authenticator app</h5>
          <p className="mt-3 mb-3 bg-light p-2 w-50 justify-content-center mx-auto">
            <small>
              Use a phone app like 1Password, Microsoft or Google Authenticator
              or an authenticator of your choice to scan the QR code.
            </small>
          </p>
          <div className="mt-3 mb-3" style={{ width: "250px" }}></div>
          {loading ? (
            <p>Loading QR Code...</p>
          ) : qrCode ? (
            <img
              src={qrCode}
              alt="QR Code"
              width={200}
              className="img-fluid shadow-lg rounded"
            />
          ) : (
            <p className="text-danger">{error}</p>
          )}
        </div>
      </div>

      {/* Right Side - Input for Authentication Code */}
      <div className="col-6 d-flex align-items-center justify-content-center bg-light">
        <div className="w-50">
          {" "}
          {/* This div centers content while keeping text left-aligned */}
          <h5 className="text-start">Verify the code from the app</h5>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Enter code"
            value={authCode}
            onChange={(e) => setAuthCode(e.target.value)}
          />
          {error && <p className="text-danger mt-2">{error}</p>}
          {/* Buttons Side-by-Side */}
          <div className="d-flex gap-2 mt-3">
            <button className="btn btn-primary" onClick={handleVerify}>
              Verify
            </button>
            <button className="btn btn-danger" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppVerification;
