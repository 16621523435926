import React from "react";
import { CDRSession } from "./types";
import UserRoleIcon from "src/components/UserRoleIcon";
import CollapsibleCard from "src/components/CollapsibleCard";
import styles from "./SessionCard.module.css";

interface SessionCardProps {
  session: CDRSession;
}

const SessionCard: React.FC<SessionCardProps> = ({ session }) => {
  // Helper function to get SIP code description
  const getSipCodeDescription = (code: string): string => {
    const descriptions: { [key: string]: string } = {
      "200": "OK - Call successful",
      "486": "Busy Here",
      "487": "Request Terminated",
      "404": "Not Found",
      "408": "Request Timeout",
      "480": "Temporarily Unavailable",
      "603": "Decline",
      "503": "Service Unavailable",
    };
    return descriptions[code] || "Unknown";
  };

  // Calculate percentages for call dispositions
  const calculatePercentage = (value: number, total: number): string => {
    if (total === 0) return "0%";
    return `${Math.round((value / total) * 100)}%`;
  };

  // Generate mock SIP code data for development/preview
  const getMockSipCodeData = () => {
    return {
      "200": 156,
      "486": 42,
      "487": 18,
      "404": 7,
      "603": 12,
      "480": 23,
    };
  };

  // Generate mock calls per day per ANI data for development/preview
  const getMockCallsPerDayPerANI = () => {
    return {
      dates: ["3/1/25", "3/2/25", "3/3/25", "3/4/25", "3/5/25"],
      data: [
        {
          ani: "5039367187",
          callCounts: [100, 10, 20, 5, 15],
        },
        {
          ani: "5039367188",
          callCounts: [100, 10, 20, 5, 30],
        },
        {
          ani: "5039367189",
          callCounts: [50, 25, 10, 15, 5],
        },
        {
          ani: "5039367190",
          callCounts: [75, 15, 5, 10, 20],
        },
      ],
    };
  };

  // Use actual data if available, otherwise use mock data
  const sipCodeData =
    Object.keys(session.sipCodeDistribution || {}).length > 0
      ? session.sipCodeDistribution
      : getMockSipCodeData();

  // Use actual reassigned number data if available, otherwise use mock data (8)
  const reassignedNumberCalls =
    session.reassignedNumberCalls !== undefined
      ? session.reassignedNumberCalls
      : 8;

  // Use actual calls per day per ANI data if available, otherwise use mock data
  const callsPerDayPerANI =
    session.callsPerDayPerANI || getMockCallsPerDayPerANI();

  return (
    <div className="card mb-3">
      <div className="card-header bg-light">
        <div className="d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
          <div className="fw-bold">
            <i className={`bi bi-filetype-csv me-2`}></i>
            {session.fileName}
          </div>

          <div className="d-flex align-items-center">
            <i className="bi bi-calendar2 me-2"></i> {session.uploadDate}
          </div>

          <div className="d-flex align-items-center">
            <span className="fw-bold me-1">Uploaded By:</span>
            {session.bitmap !== undefined && (
              <UserRoleIcon Role={session.bitmap} />
            )}
            {session.uploadedBy}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className={styles.statsContainer}>
          <div className={styles.statCard}>
            <div className={styles.statCardBody}>
              <h5 className={styles.statCardTitle}>Total Calls</h5>
              <p className={styles.statCardValue}>{session.totalCalls}</p>
            </div>
          </div>
          <div className={styles.statCard}>
            <div className={styles.statCardBody}>
              <h5 className={styles.statCardTitle}>Connected Calls</h5>
              <p className={styles.statCardValue}>{session.connectedCalls}</p>
            </div>
          </div>
          <div className={styles.statCard}>
            <div className={styles.statCardBody}>
              <h5 className={styles.statCardTitle}>Avg. Duration</h5>
              <p className={styles.statCardValue}>{session.averageDuration}</p>
            </div>
          </div>
          <div className={styles.statCard}>
            <div className={styles.statCardBody}>
              <h5 className={styles.statCardTitle}>Answer Rate</h5>
              <p className={styles.statCardValue}>{session.anserRate}</p>
            </div>
          </div>
          <div className={styles.statCard}>
            <div className={styles.statCardBody}>
              <h5 className={styles.statCardTitle}>Avg Daily Calls Per ANI</h5>
              <p className={styles.statCardValue}>
                {session.avgDailyCallsPerANI}
              </p>
            </div>
          </div>
        </div>

        <h5 className="mb-3">Compliance Analysis</h5>

        <div className="row mb-3">
          <div className="col-12">
            <CollapsibleCard
              title="Duration Violations"
              icon="bi-clock-history"
            >
              <div className="row">
                <div className="col-md-4">
                  <div
                    className={`alert ${session.durationViolations.multipleCallsIn24Hours > 0 ? "alert-warning" : "alert-success"}`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h6 className="alert-heading">24-Hour Limit</h6>
                        <p className="mb-0 small">
                          Called 3+ times within 24 hours
                        </p>
                      </div>
                      <div className="fs-4 fw-bold">
                        {session.durationViolations.multipleCallsIn24Hours}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div
                    className={`alert ${session.durationViolations.threeCallsInOneHour > 0 ? "alert-warning" : "alert-success"}`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h6 className="alert-heading">1-Hour Limit</h6>
                        <p className="mb-0 small">
                          Called 3+ times within 1 hour
                        </p>
                      </div>
                      <div className="fs-4 fw-bold">
                        {session.durationViolations.threeCallsInOneHour}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div
                    className={`alert ${session.durationViolations.twoCallsIn15Minutes > 0 ? "alert-warning" : "alert-success"}`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h6 className="alert-heading">15-Minute Limit</h6>
                        <p className="mb-0 small">
                          Called 2+ times within 15 minutes
                        </p>
                      </div>
                      <div className="fs-4 fw-bold">
                        {session.durationViolations.twoCallsIn15Minutes}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CollapsibleCard>
          </div>
        </div>

        {/* Calling Window Violations will be added in Phase 2
        <div className="row mb-3">
          <div className="col-12">
            <CollapsibleCard
              title="Calling Window Violations"
              icon="bi-calendar-x"
            >
              <div className="alert alert-warning">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <h6 className="alert-heading">Outside Permitted Hours</h6>
                    <p className="mb-0 small">
                      Calls made outside of legal calling windows
                    </p>
                  </div>
                  <div className="fs-4 fw-bold">
                    {session.callingWindowViolations}
                  </div>
                </div>
              </div>
            </CollapsibleCard>
          </div>
        </div>
      */}

        <div className="row mb-3">
          <div className="col-12">
            <CollapsibleCard title="Reassigned Numbers" icon="bi-telephone-x">
              <div
                className={`alert ${reassignedNumberCalls > 0 ? "alert-warning" : "alert-success"}`}
              >
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <h6 className="alert-heading">
                      Recently Reassigned Numbers
                    </h6>
                    <p className="mb-0 small">
                      Calls to numbers reassigned in the last 45 days
                    </p>
                  </div>
                  <div className="fs-4 fw-bold">{reassignedNumberCalls}</div>
                </div>
              </div>
            </CollapsibleCard>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12">
            <CollapsibleCard
              title="Calls Per Day Per ANI"
              icon="bi-telephone-outbound"
            >
              <div className="table-responsive">
                <table className="table table-bordered table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Phone</th>
                      {callsPerDayPerANI.dates.map((date, index) => (
                        <th key={index} className="text-center">
                          {date}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {callsPerDayPerANI.data.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="fw-bold">
                          {row.ani.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "($1) $2-$3",
                          )}
                        </td>
                        {row.callCounts.map((count, colIndex) => (
                          <td key={colIndex} className="text-center">
                            {count > 0 ? (
                              <span
                                className={
                                  count > 50 ? "text-danger fw-bold" : ""
                                }
                              >
                                {count}
                              </span>
                            ) : (
                              <span className="text-muted">-</span>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CollapsibleCard>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12">
            <CollapsibleCard
              title="SIP Response Code Distribution"
              icon="bi-code-slash"
            >
              <div className="table-responsive">
                <table className="table table-bordered table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>SIP Code</th>
                      <th>Description</th>
                      <th className="text-center">Count</th>
                      <th className="text-center">Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(() => {
                      // Calculate total count for percentages
                      const totalCount = Object.values(sipCodeData).reduce(
                        (sum, count) => sum + (count as number),
                        0,
                      );

                      return Object.entries(sipCodeData)
                        .sort(([codeA], [codeB]) => {
                          // Sort by SIP code numerically
                          return parseInt(codeA) - parseInt(codeB);
                        })
                        .map(([code, count]) => (
                          <tr key={code}>
                            <td className="fw-bold" style={{ width: "15%" }}>
                              <span className="badge bg-primary me-1">SIP</span>{" "}
                              {code}
                            </td>
                            <td>{getSipCodeDescription(code)}</td>
                            <td
                              className="text-center fw-bold"
                              style={{ width: "15%" }}
                            >
                              {count}
                            </td>
                            <td
                              className="text-center"
                              style={{ width: "15%" }}
                            >
                              {calculatePercentage(count as number, totalCount)}
                            </td>
                          </tr>
                        ));
                    })()}
                  </tbody>
                </table>
              </div>
            </CollapsibleCard>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <CollapsibleCard title="Call Dispositions" icon="bi-telephone-fill">
              <div className="table-responsive">
                <table className="table table-bordered table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Disposition Type</th>
                      <th className="text-center">Count</th>
                      <th className="text-center">Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="badge bg-success me-2">●</span>
                        Connected
                      </td>
                      <td className="text-center fw-bold">
                        {session.callDispositions?.connected || 0}
                      </td>
                      <td className="text-center">
                        {calculatePercentage(
                          session.callDispositions?.connected || 0,
                          session.callDispositions?.total || 0,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="badge bg-secondary me-2">●</span>
                        No Answer
                      </td>
                      <td className="text-center fw-bold">
                        {session.callDispositions?.noAnswer || 0}
                      </td>
                      <td className="text-center">
                        {calculatePercentage(
                          session.callDispositions?.noAnswer || 0,
                          session.callDispositions?.total || 0,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="badge bg-info me-2">●</span>
                        Voicemail
                      </td>
                      <td className="text-center fw-bold">
                        {session.callDispositions?.voicemail || 0}
                      </td>
                      <td className="text-center">
                        {calculatePercentage(
                          session.callDispositions?.voicemail || 0,
                          session.callDispositions?.total || 0,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="badge bg-warning me-2">●</span>
                        Wrong Number
                      </td>
                      <td className="text-center fw-bold">
                        {session.callDispositions?.wrongNumber || 0}
                      </td>
                      <td className="text-center">
                        {calculatePercentage(
                          session.callDispositions?.wrongNumber || 0,
                          session.callDispositions?.total || 0,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="badge bg-light text-dark me-2">●</span>
                        Other
                      </td>
                      <td className="text-center fw-bold">
                        {session.callDispositions?.other || 0}
                      </td>
                      <td className="text-center">
                        {calculatePercentage(
                          session.callDispositions?.other || 0,
                          session.callDispositions?.total || 0,
                        )}
                      </td>
                    </tr>
                    <tr className="table-light">
                      <td className="fw-bold">Total</td>
                      <td className="text-center fw-bold">
                        {session.callDispositions?.total || 0}
                      </td>
                      <td className="text-center">100%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CollapsibleCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionCard;
