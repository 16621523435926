import React from "react";

interface ToolTipProps {
  description: string;
}

const ToolTip: React.FC<ToolTipProps> = ({ description }) => {
  return (
    <>
      <i
        className="bi bi-info-circle ps-1 pe-1"
        style={{ cursor: "pointer" }}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={description}
        data-bs-original-title={description}
        aria-label={description}
      ></i>
    </>
  );
};

export default ToolTip;
