import React, { useState } from "react";
import { CDRSession } from "./types";
import SessionCard from "./SessionCard";

interface SessionsProps {
  sessions: CDRSession[];
}

const Sessions: React.FC<SessionsProps> = ({ sessions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const sessionsPerPage = 10;

  // Calculate total pages
  const totalPages = Math.ceil(sessions.length / sessionsPerPage);

  // Get current sessions
  const indexOfLastSession = currentPage * sessionsPerPage;
  const indexOfFirstSession = indexOfLastSession - sessionsPerPage;
  const currentSessions = sessions.slice(
    indexOfFirstSession,
    indexOfLastSession,
  );

  // Change page
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="mb-4">
      <h4 className="mb-3">CDR Analysis Sessions</h4>

      {sessions.length === 0 ? (
        <div className="alert alert-info">
          <i className="bi bi-info-circle me-2"></i>
          No CDR analysis sessions available. Upload a CDR file to begin
          analysis.
        </div>
      ) : (
        <>
          {currentSessions.map((session) => (
            <SessionCard key={session.sessionId} session={session} />
          ))}

          {/* Pagination */}
          {totalPages > 1 && (
            <nav aria-label="Session pagination" className="mt-4">
              <ul className="pagination justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {pageNumbers.map((number) => (
                  <li
                    key={number}
                    className={`page-item ${currentPage === number ? "active" : ""}`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(number)}
                    >
                      {number}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </>
      )}
    </div>
  );
};

export default Sessions;
