import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { HttpMethod, callApi } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import IframeComponent from "src/components/IframeComponent";
import ConfirmationModal from "src/components/ConfirmationModal";
import { useForm } from "react-hook-form";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";
import Warning from "src/components/Warning";
import DOMPurify from "dompurify";
import { FtcSan } from "src/types/ftcSan";
import ICpaSection from "src/interfaces/ICpaSection";
import INewProject from "src/interfaces/Main/INewProject";
import IFtcInfo from "src/interfaces/Main/IFtcInfo";
import ICampaignRow from "src/interfaces/Main/ICampaignRow";
import SaveInProgress from "src/components/SaveInProgress";
import Styles from "./Main.module.css";
import UserRoleIcon from "src/components/UserRoleIcon";
import EmailTextarea from "src/components/EmailTextarea";
import { formatDate } from "src/utils/formatUtils";

interface ExemptionCategory {
  categoryId: number;
  level: number;
  displayName: string;
  checked: boolean;
}

const Main = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { isSysadmin } = useAppSelector(selectAuth);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    watch,
    setValue,
  } = useForm();
  const allValues = watch();
  const isChecked = watch("project.UsesMasterProjDNCsub");
  const [access, setAccess] = useState<ICpaSection>();
  const [createdByFullName, setCreatedByFullName] = useState<string | null>(
    null,
  );
  const [createdByUserRold, setCreatedByUserRole] = useState<number | null>(
    null,
  );
  const [createdByUserStatus, setCreatedByUserStatus] = useState<number | null>(
    null,
  );
  const [deactivatedByFullName, setDeactivatedByFullName] = useState<
    string | null
  >(null);
  const [deactivatedByUserRole, setDeactivatedByUserRole] = useState<
    number | null
  >(null);
  const [deactivatedByUserStatus, setDeactivatedByUserStatus] = useState<
    number | null
  >(null);
  const [isCrossAccount, setIsCrossAccount] = useState<boolean>(true);
  const [isTrainingAdmin, setIsTrainingAdmin] = useState<boolean>(false);
  const [seesMasterProjUsages, setSeesMasterProjUsages] =
    useState<boolean>(true);
  const [isNonSubMaster, setIsNonSubMaster] = useState<boolean>(false);
  const [checkboxStatus, setCheckboxStatus] = useState<boolean>(false); // True means checked and disabled
  const [masterProjUsagesMessage, setMasterProjUsagesMessage] =
    useState<string>("");
  const [isNewProject, setIsNewProject] = useState<boolean>(false);
  const [projectCodeNew, setProjectCodeNew] = useState<string>("");
  const [projectNameNew, setProjectNameNew] = useState<string>("");
  const [newProjectData, setNewProjectData] = useState<INewProject | null>(
    null,
  );
  const [ftcInfo, setFtcInfo] = useState<IFtcInfo | null>(null);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const [projectData, setProjectData] = useState<string | null>(null);
  const [projectAccountData, setProjectAccountData] = useState<string | null>(
    null,
  );
  const [ftcOrgIdsData, setFtcOrgIdsData] = useState<string | null>(null);
  const [ftcSansData, setFtcSansData] = useState<string | null>(null);
  const {
    projectId,
    setProjectName,
    newProjectName,
    setNewProjectName,
    campaignId,
    setCampaignName,
    setSourceComponent,
    setRefreshTree,
  } = useUploadListContext();

  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [displayIframe, setDisplayIframe] = useState<boolean>(false);
  const showIframe = (url: string) => {
    setIframeUrl(url);
    setDisplayIframe(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [exemptionCategories, setExemptionCategories] = useState<
    ExemptionCategory[]
  >([]);
  const [exemptionsData, setExemptions] = useState<string | null>(null);
  const [warningMessage, setWarningMessage] = useState("");
  const [ftcSansRec, setFtcSansRec] = useState<any>(null);
  const [projIdAvailable, setProjIdAvailable] = useState("");
  const [defaultCampaignId, setDefaultCampaignId] = useState<number | null>(
    null,
  );
  const [campaignRow, setCampaignRow] = useState<ICampaignRow | null>(null);

  const sanitizeInput = (input: string): string =>
    DOMPurify.sanitize(input, { ALLOWED_TAGS: [] });

  const resetData = () => {
    setProjectData(null);
    setProjectAccountData(null);
    setFtcOrgIdsData(null);
    setFtcSansData(null);
    setExemptions(null);
    setDefaultCampaignId(null);
    setSeesMasterProjUsages(true);
    setMasterProjUsagesMessage("");

    //New
    setCreatedByFullName(null);
    setCreatedByUserRole(null);
    setCreatedByUserStatus(null);
    setDeactivatedByFullName(null);
    setDeactivatedByUserRole(null);
    setDeactivatedByUserStatus(null);
  };

  useEffect(() => {
    //Old data still is showing when the project changes - AK
    resetData();
  }, [projectId]);

  // This is exists so when the user closes the FTC Subscription editor
  // the component will re-render and fetch the updated FTC data
  const [popupFTCEditorClosed, setPopupFTCEditorClosed] = useState(false);

  useEffect(() => {
    if (isNewProject) {
      setValue("projectName", newProjectName, { shouldDirty: true });
      setValue("projectCode", projectCodeNew, { shouldDirty: true });
      setFtcSansRec("");
    } else {
      setFtcSansRec(JSON.parse(JSON.stringify(ftcSansData)));
    }
  }, [ftcOrgIdsData, ftcSansData, isNewProject]);

  const fetchAndUpdateProjectData = useCallback(
    async (projId: string, campId: string) => {
      try {
        setWarningMessage(``);
        const apiUrl = `main/Projects/GetProject?projId=${projId}&campaignId=${campId}`;
        const response = await callApi(apiUrl, HttpMethod.GET);

        setProjectData(response.Project);
        setProjectAccountData(response.ProjectAccounts);
        if (isNewProject) {
          setFtcOrgIdsData(null);
          setFtcSansData(null);
        } else {
          setFtcOrgIdsData(null);
          setFtcSansData(response.FtcSans);
        }
        setCreatedByFullName(response.CreatedBy);
        setCreatedByUserRole(response.CreatedByUserRole);
        setCreatedByUserStatus(response.CreatedByUserStatus);
        setDeactivatedByFullName(response.DeactivatedBy);
        setDeactivatedByUserRole(response.DeactivatedByUserRole);
        setDeactivatedByUserStatus(response.DeactivatedByUserStatus);
        setExemptions(response.Exemptions);
        if (
          response.CrossAccount === "true" ||
          response.CrossAccount === true
        ) {
          setIsCrossAccount(true);
        } else {
          setIsCrossAccount(false);
        }
        if (
          response.TrainingAdmin === "true" ||
          response.TrainingAdmin === true
        ) {
          setIsTrainingAdmin(true);
        } else {
          setIsTrainingAdmin(false);
        }
        if (response.CampaignRow) {
          setCampaignRow(response.CampaignRow);
        }

        if (
          response.CampaignRow.isMaster == true ||
          response.projectsCannotUseMaster == true
        ) {
          setSeesMasterProjUsages(false);
        } else if (response.SubAccountsMustUseMaster == true) {
          if (response.CampaignRow.isSubAccount == 1) {
            setCheckboxStatus(true);
          }
          setMasterProjUsagesMessage(
            "<div class='marginJ'><span class='body3'>In this account's base plan, projects that are sub-account-enabled are required to use the Master Project's National DNC Subscription.</span><br>",
          );
        }

        if (
          response.CampaignRow.isMasterSomewhere &&
          response.CampaignRow.isSubAccount !== 1
        ) {
          setIsNonSubMaster(true);
        } else {
          setIsNonSubMaster(false);
        }

        setAccess(response.CpaSection);
        setFtcInfo(response.FtcInfo);
        setDefaultCampaignId(response.DefaultCampaignId);

        const categoriesWithChecked = response.ExemptionCategories.map(
          (category: ExemptionCategory) => ({
            ...category,
            checked: response.SelectedCategories.includes(category.categoryId),
          }),
        );
        setExemptionCategories(categoriesWithChecked);
      } catch (error) {
        setWarningMessage(`Failed to fetch project data`);
        console.error("Failed to fetch project data:", error);
      }
    },
    [isNewProject, popupFTCEditorClosed],
  );

  const fetchNewProjectData = useCallback(async () => {
    const projectDiv = document.querySelector(
      ".show-scroll-back-to-top",
    ) as HTMLElement;
    try {
      setWarningMessage(``);
      setIsNewProject(true);
      setProjectNameNew(newProjectName);
      const apiUrl = `main/Projects/StartNewProject?projId=${projectId}&campaignId=${campaignId}&name=${newProjectName}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      if (response.insert !== true) {
        setWarningMessage(`Failed to fetch project data`);
        return;
      }
      setNewProjectData(response);
      setProjectCodeNew(response.projectCode || "");
      // scroll to top
      projectDiv.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      //if (response.defaultUseMaster === "true") {
      fetchAndUpdateProjectData(
        response.parentAcctId,
        response.defaultCampaignId,
      );
      setFtcOrgIdsData(null);
      setFtcSansData(null);
      //get rid of extra data

      //}
    } catch (error) {
      setWarningMessage(`Failed to fetch project data`);
      console.error("Failed to fetch project data:", error);
    }
  }, [
    fetchAndUpdateProjectData,
    newProjectName,
    projectId,
    campaignId,
    setProjectNameNew,
  ]);

  useEffect(() => {
    if (!campaignId) return;

    if (newProjectName) {
      const sanitizedNewProjectName = sanitizeInput(newProjectName);
      if (sanitizedNewProjectName !== newProjectName) {
        setProjectNameNew(sanitizedNewProjectName);
      }
      setCampaignName("Default Campaign");
      fetchNewProjectData();
      setNewProjectName("");
    } else {
      fetchAndUpdateProjectData(projectId, campaignId);
    }
  }, [
    projectId,
    campaignId,
    newProjectName,
    setProjectName,
    setCampaignName,
    fetchNewProjectData,
    fetchAndUpdateProjectData,
    setNewProjectName,
  ]);

  const checkProjectIdExists = useCallback(
    async (projectId: string) => {
      try {
        if (!isNewProject) return;
        // Abhi: naming the querystring Id as the middleware in API kicks in if it is projId
        let newProjectCode = projectId;
        if (newProjectData?.prefix) {
          newProjectCode = `${newProjectData.parentAcctId}_${projectId}`;
        }

        const apiUrl = `main/Projects/IsProjIdAvailable?Id=${newProjectCode}`;
        const response = await callApi(apiUrl, HttpMethod.GET);
        if (!response) {
          setProjIdAvailable(
            `Project ID "${newProjectCode}" is already in use. Please choose another.`,
          );
        } else {
          setProjIdAvailable("");
        }
      } catch (error) {
        console.error("Error checking project ID existence:", error);
        setProjIdAvailable("Failed to verify project ID.");
      }
    },
    [newProjectData],
  );

  // Trigger the check when the project code changes
  useEffect(() => {
    if (projectCodeNew) {
      checkProjectIdExists(projectCodeNew);
    }
  }, [projectCodeNew, checkProjectIdExists, newProjectData]);

  useEffect(() => {
    //Added this as the data was not updating after API call - AK
    const projectRec = JSON.parse(JSON.stringify(projectData));
    let jsonString = JSON.stringify(projectAccountData);
    const projectAccountRec = JSON.parse(jsonString);
    if (isNewProject) {
      setValue("projectName", projectNameNew);
      setValue("projectCode", projectCodeNew);
      setValue("project.Notes", "");
    } else {
      setValue("project.projId", projectRec?.projId);
      setValue("project.Name", projectRec?.name);
      setValue("project.Notes", projectRec?.notes);
    }
    setValue("project.UsesMasterProjDNCdb", projectRec?.usesMasterProjDNCdb);
    setValue("project.UsesMasterProjEBRdb", projectRec?.usesMasterProjEBRdb);
    setValue(
      "project.UsesMasterProjDNCpolicy",
      projectRec?.usesMasterProjDNCpolicy,
    );
    setValue(
      "project.UsesMasterProjTraining",
      projectRec?.usesMasterProjTraining,
    );
    setValue("project.UsesMasterProjDNCsub", projectRec?.usesMasterProjDNCsub);
    setValue("projectAccount.Id", projectAccountRec?.Id);
    setValue(
      "projectAccount.ScrubSummaryActive",
      projectAccountRec?.ScrubSummaryActive,
    );
    setValue(
      "projectAccount.ScrubSummaryAllProjects",
      projectAccountRec?.ScrubSummaryAllProjects,
    );
    setValue(
      "projectAccount.SupressScrubSummaryIfNoScrubs",
      projectAccountRec?.SupressScrubSummaryIfNoScrubs,
    );
    setValue(
      "projectAccount.ScrubSummaryRecipients",
      projectAccountRec?.ScrubSummaryRecipients,
    );
    setValue(
      "projectAccount.ScrubSummaryFrequency",
      projectAccountRec?.ScrubSummaryFrequency || "1",
    );
    setValue(
      "projectAccount.ScrubSummaryDayOfWeek",
      projectAccountRec?.ScrubSummaryDayOfWeek || "0",
    );
  }, [
    projectData,
    projectAccountData,
    isNewProject,
    projectCodeNew,
    projectNameNew,
    setValue,
  ]);

  if (!projectData || !projectAccountData) {
    return <Loading />;
  }

  const projectRec = JSON.parse(JSON.stringify(projectData));

  let jsonString = JSON.stringify(projectAccountData);
  const projectAccountRec = JSON.parse(jsonString);

  const handleDeactivateClick = async (event: any) => {
    event.preventDefault(); // Prevent main part of form from submitting

    try {
      let apiUrl = `main/Projects/ToggleActiveProject?projectId=${projectId}`;
      const apiResponse = await callApi(apiUrl, HttpMethod.GET);
      if (apiResponse) {
        fetchAndUpdateProjectData(projectId, campaignId);
      }
      setRefreshTree(true);
    } catch (error) {
      console.error("Failed to deactivate project:", error);
    }
  };

  const openPopupWindow = (
    url: string,
    windowName: string,
    windowOptions: string,
  ) => {
    const popupFTCEditor = window.open(url, windowName, windowOptions);
    setPopupFTCEditorClosed(false);
    const checkPopupClosed = () => {
      if (popupFTCEditor && popupFTCEditor.closed) {
        setPopupFTCEditorClosed(true);
        clearInterval(popupCheckInterval);
      }
    };

    const popupCheckInterval = setInterval(checkPopupClosed, 500);
  };

  const handleInputFTCSubscriptionButtonClick = () => {
    const url = `https://core.dncscrub.com/app/main/FTCDNCSubscription?projId=${projectId}`;
    openPopupWindow(url, "Input FTC Subscription", "'width=800,height=600");
  };

  const onSubmit = async (data: any) => {
    setSaveInProgress(true);

    try {
      setWarningMessage(``);
      // Send a request to create a new project

      let newData: any = {};
      let newProjectCode = sanitizeInput(projectCodeNew);
      let newProjectName = sanitizeInput(projectNameNew);
      let campId = campaignId;
      if (newProjectData?.prefix) {
        newProjectCode = `${newProjectData.parentAcctId}_${newProjectCode}`;
      }
      newData = {
        projectName: newProjectName,
        projectCode: newProjectCode,
        isNewProject: true,
      };
      if (isNewProject) {
        const apiUrl = `main/Projects/AddNewProject?projId=${projectId}&campaignId=${campaignId}`;
        const response = await callApi(apiUrl, HttpMethod.POST, newData);
        if (response.type !== "SUCCESS") {
          console.log("New project was not created successfully");
        } else {
          const newCampId = response.message;
          data.project.projId = newProjectCode;
          data.project.Name = newProjectName;
          data.project.campaignId = newCampId;
          campId = newCampId;
        }
      }
      const apiUrlForUpdate = "main/Projects/UpdateProjectSettings";
      const responseForUpdate = await callApi(
        apiUrlForUpdate,
        HttpMethod.POST,
        data,
      );
      if (responseForUpdate.type === "SUCCESS") {
        //if (data.project.projectName !== projectName) setRefreshTree(true);
        //redirect the user to the new campaign url so that the defaults are loaded correctly.
        setIsNewProject(false);
        setRefreshTree(true);
        navigate(`/main/project-settings/${campId}`);
      } else {
        setWarningMessage(`Failed to save project settings`);
      }
    } catch (error) {
      setWarningMessage(`Failed to save project settings`);
      console.error("Failed to save project settings:", error);
    }

    setSaveInProgress(false);
  };

  const ownershipText = isCrossAccount ? "your" : "its";

  const navigateToProjectScrubOptions = () => {
    if (Number(campaignId) === defaultCampaignId) {
      handleScrubOptionsConfirm();
    } else {
      setShowModal(true);
    }
  };

  const handleScrubOptionsConfirm = () => {
    setSourceComponent("Project");
    setShowModal(false);
    const url =
      defaultCampaignId && defaultCampaignId !== 0
        ? `/main/scrub-options/${defaultCampaignId}`
        : "/main/scrub-options";
    navigate(url);
  };

  const handleScrubOptionsCancel = () => {
    setShowModal(false);
  };

  if (!access) return <Loading />;

  // This form has three save buttons so putting it in
  // component to avoid repeating the code
  const SaveButton = ({
    isDirty,
    isValid,
    saveInProgress,
  }: {
    isDirty: boolean;
    isValid: boolean;
    saveInProgress: boolean;
  }) => (
    <button
      type="submit"
      className="btn btn-primary me-2"
      disabled={!isDirty || !isValid}
    >
      {!saveInProgress ? "Save" : <SaveInProgress isVisible={saveInProgress} />}
    </button>
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Warning message={warningMessage} />
        <div style={{ display: "none" }}>
          <p>
            Abhi K: Leaving this in place to make it easier to debug. Also need
            hidden fields for save to work
          </p>
          <pre>{JSON.stringify(allValues, null, 2)}</pre>
          <input
            type="hidden"
            {...register("project.projId")}
            value={projectRec?.projId}
          />
          <input
            type="hidden"
            {...register("projectAccount.Id")}
            value={projectAccountRec?.Id}
          />
        </div>
        <br />
        {!displayIframe && (
          <>
            {isNewProject ? (
              <>
                <div className="row">
                  <div className="col-9">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        New Project Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          id="projectName"
                          {...register("projectName", {
                            required: true,
                            onChange: (e) => setProjectNameNew(e.target.value),
                          })}
                          value={projectNameNew}
                          className={`col-3 form-control ${errors.projectName ? "is-invalid" : ""}`}
                        />
                        {errors.projectName && (
                          <div className="invalid-feedback">
                            Project Name is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        New Project Code
                      </label>
                      <div className="col-sm-9">
                        {newProjectData?.prefix && (
                          <div>{newProjectData.parentAcctId}_</div>
                        )}
                        <input
                          id="projectCode"
                          {...register("projectCode", {
                            required: "Project Code is required",
                            validate: {
                              //noSpaces: (value) => !/\s/.test(value) || "No spaces allowed",
                              noLeadingNumber: (value) =>
                                !/^\d/.test(value) ||
                                "Cannot start with a number",
                              alphanumeric: (value) =>
                                /^[a-zA-Z0-9]*$/.test(value) ||
                                "Only alphanumeric characters are allowed",
                            },
                            onChange: (e) => {
                              const upperCaseValue =
                                e.target.value.toUpperCase();
                              setProjectCodeNew(upperCaseValue);
                              setValue("projectCode", upperCaseValue);
                            },
                          })}
                          value={projectCodeNew}
                          className={`col-3 form-control ${errors.projectCode ? "is-invalid" : ""}`}
                        />
                        <div className="text-danger">{projIdAvailable}</div>
                        {errors.projectCode && (
                          <div className="invalid-feedback">
                            {typeof errors.projectCode.message === "string" &&
                              errors.projectCode.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <SaveButton
                      isDirty={isDirty}
                      isValid={isValid}
                      saveInProgress={saveInProgress}
                    />
                  </div>
                </div>
                <hr />
              </>
            ) : (
              <>
                {!isNewProject &&
                  campaignRow?.isMasterSomewhere === 0 &&
                  projectRec.status === 1 &&
                  access.deactivate === true && (
                    <>
                      <h6>Deactivate Project:</h6>
                      <div>
                        You can deactivate a project to hide it from a list and
                        prevent it from being retroactively scrubbed.
                      </div>
                      <button
                        className="btn btn-danger"
                        onClick={handleDeactivateClick}
                      >
                        Deactivate
                      </button>
                      <hr />
                    </>
                  )}

                {!isNewProject &&
                  campaignRow?.isMasterSomewhere === 0 &&
                  projectRec.status === 2 &&
                  access.reactivate === true && (
                    <>
                      <h6>This project is deactivated.</h6>
                      <div>
                        You can reactivate a project to show it in a list and
                        have it be retroactively scrubbed.
                      </div>
                      <button
                        className="btn btn-secondary"
                        onClick={handleDeactivateClick}
                      >
                        Reactivate
                      </button>
                      <hr />
                    </>
                  )}

                {!isNewProject && !isNonSubMaster && (
                  <>
                    <div className="row align-items-center">
                      <div className="col-md-8">
                        <div className="d-flex align-items-center">
                          <h6 className="me-2 mb-0">Rename Project:</h6>
                          <div className="flex-grow-1">
                            <input
                              {...register("project.Name", { required: true })}
                              className={`form-control ${errors["project.Name"] ? "is-invalid" : ""}`}
                            />
                            {errors["project.Name"] && (
                              <span className="text-danger">
                                Project name is required
                              </span>
                            )}
                          </div>
                        </div>
                        <h6 className="mt-1">Project Code: {projectId}</h6>
                      </div>
                      <div className="col-md-4 text-end">
                        <SaveButton
                          isDirty={isDirty}
                          isValid={isValid}
                          saveInProgress={saveInProgress}
                        />
                      </div>
                    </div>
                    <hr />
                  </>
                )}
              </>
            )}
            {!isNewProject && (
              <div className="mb-3">
                {(createdByFullName || projectRec.createdOn) && (
                  <div className="d-flex align-items-start">
                    <div className="col d-flex align-items-center justify-content-start">
                      {createdByFullName ? (
                        <>
                          <span className="fw-bold">Created By:</span>
                          <span className="ms-2 d-flex align-items-center">
                            <UserRoleIcon
                              Role={createdByUserRold}
                              Status={createdByUserStatus}
                            />
                            <span className="ms-1">{createdByFullName}</span>
                          </span>
                        </>
                      ) : null}
                      {createdByFullName ? (
                        projectRec.createdOn && (
                          <span className="ps-2 pe-2 fw-bolder">on</span>
                        )
                      ) : (
                        <span className="fw-bold">Created On:&nbsp;</span>
                      )}
                      {projectRec.createdOn && (
                        <span>{formatDate(projectRec.createdOn)}</span>
                      )}
                    </div>
                  </div>
                )}
                {projectRec.status === 2 &&
                  deactivatedByFullName &&
                  projectRec.deactivatedOn && (
                    <div className="d-flex align-items-start">
                      <div className="col d-flex align-items-center justify-content-start">
                        <span className="fw-bold">Deactivated By:</span>
                        <span className="ms-2 d-flex align-items-center">
                          <UserRoleIcon
                            Role={deactivatedByUserRole}
                            Status={deactivatedByUserStatus}
                          />
                          <span className="ms-1">
                            {deactivatedByFullName} on{" "}
                            {formatDate(projectRec.deactivatedOn)}
                          </span>
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            )}

            <div className="row align-items-top mb-3">
              <div className="col-auto">
                <h6 className="mt-2">Project Notes:</h6>
              </div>
              <div className="col">
                <textarea
                  {...register("project.Notes")}
                  className="form-control"
                  rows={2}
                />
              </div>
            </div>

            {!isNewProject && seesMasterProjUsages && (
              <>
                <hr />
                {masterProjUsagesMessage}
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    {...register("project.UsesMasterProjDNCdb")}
                    disabled={isCrossAccount}
                  />
                  <span className="ps-2">
                    This project uses {ownershipText} Master Project's DNC/DNM
                    database.
                  </span>
                </label>
                <br />
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    {...register("project.UsesMasterProjEBRdb")}
                    disabled={isCrossAccount}
                  />
                  <span className="ps-2">
                    This project uses {ownershipText} Master Project's EBRMaster
                    Override database.
                  </span>
                </label>
                <br />
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    {...register("project.UsesMasterProjDNCpolicy")}
                    disabled={checkboxStatus || isCrossAccount}
                  />
                  <span className="ps-2">
                    This project uses {ownershipText} Master Project's DNC
                    Policy.
                  </span>
                </label>
                {isTrainingAdmin && (
                  <>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        {...register("project.UsesMasterProjTraining")}
                        disabled={checkboxStatus || isCrossAccount}
                      />
                      <span className="ps-2">
                        This project uses {ownershipText} Master Project's
                        Training Courses.
                      </span>
                    </label>
                  </>
                )}
                <br />
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    {...register("project.UsesMasterProjDNCsub")}
                    disabled={isCrossAccount}
                  />
                  <span className="ps-2">
                    This project uses {ownershipText} Master Project's National
                    DNC Subscription.
                  </span>
                </label>
                <br />
              </>
            )}
            {!isChecked && (
              <>
                <hr />
                <div className="card">
                  <div className="card-header">
                    US National DNC Subscription
                  </div>
                  <div className="card-body">
                    <p>
                      National DNC Registry Organization Id:{" "}
                      <b>{ftcInfo?.OrgId}</b>
                    </p>
                    <p>
                      To provide or update your National DNC Subscription,
                      please email <b>support@dnc.com</b> with your Organization
                      ID and SAN
                    </p>
                    {isSysadmin && (
                      <button
                        className="btn btn-primary"
                        onClick={handleInputFTCSubscriptionButtonClick}
                      >
                        Input FTC Subscription
                      </button>
                    )}
                    <table className="table mt-3">
                      <thead>
                        <tr>
                          <th colSpan={2}>Org Id: {ftcInfo?.OrgId}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>SAN(s):</td>
                          <td>
                            <ul className={Styles["san-list-style"]}>
                              {ftcSansRec &&
                                ftcSansRec.map(
                                  (record: FtcSan, index: number) => (
                                    <li key={index}>
                                      {record.San}
                                      {record.Expiration && (
                                        <span className="ps-3">
                                          Expires:{" "}
                                          {formatDate(record.Expiration)}
                                        </span>
                                      )}
                                    </li>
                                  ),
                                )}
                            </ul>
                          </td>
                        </tr>

                        <tr>
                          <td>States Included:</td>
                          <td>
                            {ftcInfo?.EntireCountry
                              ? `Entire ${ftcInfo?.CountryName || "United States"}`
                              : ftcInfo?.States || ""}
                          </td>
                        </tr>
                        <tr>
                          <td>Addl. Area Codes Included:</td>
                          <td>
                            {ftcInfo?.EntireCountry ? "" : ftcInfo?.AreaCodes}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            <div className="card mt-3">
              <div className="card-header">Scrub Summary Report</div>
              <div className="card-body">
                <div>
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      {...register("projectAccount.ScrubSummaryActive")}
                    />
                    <span className="ps-2">
                      Enable Scrub Summary report for this Project
                    </span>
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      {...register("projectAccount.ScrubSummaryAllProjects")}
                    />
                    <span className="ps-2">
                      Include all projects in the summary report
                    </span>
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      {...register(
                        "projectAccount.SupressScrubSummaryIfNoScrubs",
                      )}
                    />
                    <span className="ps-2">
                      Only send summary report on days when scrubs have occurred
                    </span>
                  </label>
                </div>
                <br />
                <h6>E-mail reports To:</h6>
                <EmailTextarea
                  name="projectAccount.ScrubSummaryRecipients"
                  control={control}
                />
                <br />
                <h6>Frequency of updates:</h6>
                <select
                  {...register("projectAccount.ScrubSummaryFrequency")}
                  className="form-control form-select"
                >
                  <option value="1">Daily</option>
                  <option value="7">Every Week</option>
                </select>
                <h6>If weekly, day of week to receive report:</h6>
                <select
                  {...register("projectAccount.ScrubSummaryDayOfWeek")}
                  className="form-control form-select"
                >
                  <option value="0">Sunday</option>
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                </select>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-header">IntelliScrub Settings</div>
              <div className="card-body">
                This Project is for a company in these product categories.
                <br />
                {exemptionsData && (
                  <div className="bg-warning text-black p-2 mt-2 mb-2">
                    Warning: Your Project settings grant exemptions in these
                    States: <b>{exemptionsData}.</b>
                  </div>
                )}
                <p>Business/Product Categories:</p>
                {exemptionCategories &&
                  exemptionCategories.map((category) => {
                    const paddingClass = `ps-${
                      category.level === 1 ? 1 : category.level + 2
                    }`;

                    return (
                      <div key={category.categoryId} className={paddingClass}>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          {...register(`categories.${category.categoryId}`)}
                          defaultChecked={category.checked}
                          id={`category-${category.categoryId}`}
                        />
                        <label
                          htmlFor={`category-${category.categoryId}`}
                          className="ps-2"
                        >
                          {category.displayName}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        )}
        <br />
        {displayIframe && (
          <>
            <IframeComponent
              title="Calling Holidays and Times"
              src={iframeUrl || "about:blank"}
              allowFullScreen
              style={{
                backgroundColor: "var(--neutral-light)", // Keep consistent appearance in dark mode
                width: "100%",
                height: "calc(100vh - var(--height-of-top-nav-bar) - 100px)",
                border: "0",
                zIndex: -1, // Ensure the menu displays on top
              }}
            />
          </>
        )}
        {(!displayIframe && (
          <div className="page-buttons">
            <SaveButton
              isDirty={isDirty}
              isValid={isValid}
              saveInProgress={saveInProgress}
            />

            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={navigateToProjectScrubOptions}
            >
              Project Details / Default Scrub Options...
            </button>
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={() => {
                showIframe(
                  `https://core.dncscrub.com/app/main/nocallholidays?projId=${projectId}&campaignId=${campaignId}&noSideBar=true`,
                );
              }}
            >
              No Call Holidays
            </button>
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={() =>
                showIframe(
                  `https://core.dncscrub.com/app/main/CustomCallingWindow?projId=${projectId}&campaignId=${campaignId}&noSideBar=true`,
                )
              }
            >
              Calling Time Windows
            </button>
          </div>
        )) || (
          <button
            className="btn btn-secondary me-2"
            onClick={() => {
              setDisplayIframe(false);
            }}
          >
            Back to project settings Home
          </button>
        )}
        <ConfirmationModal
          isVisible={showModal}
          message="We will change your campaign to the project's default campaign as you are editing the project's default scrub options."
          onConfirm={handleScrubOptionsConfirm}
          onCancel={handleScrubOptionsCancel}
        />
      </form>
    </>
  );
};

export default Main;
