import React from "react";
import { DropzoneRootProps, DropzoneInputProps } from "react-dropzone";
import styles from "./FileUploadContainer.module.css";

interface FileUploadContainerProps {
  getRootProps: (props?: any) => DropzoneRootProps;
  getInputProps: (props?: any) => DropzoneInputProps;
  isDragActive: boolean;
  title: string;
  iconClassName: string;
}

const FileUploadContainer: React.FC<FileUploadContainerProps> = ({
  getRootProps,
  getInputProps,
  isDragActive,
  title,
  iconClassName,
}) => {
  return (
    <div
      {...getRootProps()}
      className={`col ${styles["upload-file-container"]}`}
      data-drag-active={isDragActive}
    >
      <input {...getInputProps()} />
      <div className="d-flex flex-column">
        <div className="d-flex mt-2 align-items-center justify-content-center">
          <span className="text-center">{title}</span>
          <i
            className={`bi ${iconClassName} ${styles["upload-list-icon"]}`}
          ></i>
        </div>
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag and drop files here, or click to select files</p>
        )}
      </div>
    </div>
  );
};

export default FileUploadContainer;
