import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { HttpMethod, callApi } from "src/services/apiService";
import Loading from "src/components/Loading";
import Warning from "src/components/Warning";
import SaveInProgress from "src/components/SaveInProgress";
import { selectAuth } from "src/features/authSlice";
import { useAppSelector } from "src/app/hooks";
import {
  IIntelliScrubDb,
  IntelliScrubDbItem,
} from "src/interfaces/Admin/IIntelliScrub";

const IntelliScrubDatabases = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [warningMessage, setWarningMessage] = useState("");
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [data, setData] = useState<IIntelliScrubDb>();
  const { register, handleSubmit, setValue } = useForm();
  const [originalData, setOriginalData] = useState<IIntelliScrubDb | null>(
    null,
  ); // Using for reset
  const { isSysadmin } = useAppSelector(selectAuth);

  useEffect(() => {
    const fetchIntelliScrubData = async () => {
      setIsLoading(true);
      try {
        const apiUrl = `admin/IntelliScrub`;
        const response = await callApi(apiUrl, HttpMethod.GET);
        setData(response);
        setOriginalData(response);
      } catch (error) {
        setWarningMessage("Failed to fetch IntelliScrub settings data.");
        console.error("Failed to fetch IntelliScrub settings data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchIntelliScrubData();
  }, []);

  useEffect(() => {
    if (data) {
      data.intelliscrubDbs.forEach((db) => {
        setValue(`status-${db.id}`, db.accountStatus);
      });
    }
  }, [data, setValue]);

  const onSubmit = async (formData: any) => {
    //console.log(formData);
    setSaveInProgress(true);

    const transformedData = Object.entries(formData).map(([key, value]) => ({
      Key: key,
      Value: parseInt(value as string, 10), // Ensure value is an integer
    }));

    try {
      const apiUrl = `admin/IntelliScrub`;
      const method = HttpMethod.POST;
      const body = transformedData;

      await callApi(apiUrl, method, body);
      console.log("Settings saved successfully");
    } catch (error) {
      console.error("Failed to save settings:", error);
    }
    setSaveInProgress(false);
  };

  const handleUpdateAll = (statusValue: number) => {
    data?.intelliscrubDbs.forEach((db) => {
      if (!isDropdownDisabled(db)) {
        setValue(`status-${db.id}`, statusValue);
      }
    });
  };

  const handleReset = () => {
    originalData?.intelliscrubDbs.forEach((db) => {
      setValue(`status-${db.id}`, db.accountStatus);
    });
  };

  const handleBlockAll = () => {
    data?.intelliscrubDbs.forEach((db) => {
      if (db.isBlockable === 1) {
        setValue(`status-${db.id}`, 0);
      }
    });
  };

  const handleResetToPlanDefault = () => {
    data?.intelliscrubDbs.forEach((db) => {
      setValue(`status-${db.id}`, db.rootStatus);
    });
  };

  const isDropdownDisabled = (db: IntelliScrubDbItem): boolean => {
    if (db.isPremium && originalData?.cpa) {
      const key = db.shortName.toLowerCase();
      if (db.id === 44 && originalData.cpa.litigator !== "1") {
        return true;
      } else if (db.id !== 44 && originalData.cpa[key] !== "1") {
        return true;
      }
    }
    if (db.stateAuthorizationRequired === true) {
      //if sysadmin, allow changes
      if (isSysadmin) {
        return false;
      }
      return true;
    }
    return false;
  };

  if (warningMessage) {
    return <Warning message={warningMessage} />;
  }

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-1 pb-1">
          <h1>IntelliScrub Databases</h1>
          {isLoading || !data ? (
            <div className="container-fluid">
              <Loading />
            </div>
          ) : (
            <>
              <p>{data.contentRepository.crShort}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: data.contentRepository.crLong,
                }}
              ></p>
              <table className="table mb-5 table-striped table-thin">
                <thead>
                  <tr>
                    <th>Name [$ = Premium DB]</th>
                    <th>Update Frequency</th>
                    <th>Last Activated in DNCScrub</th>
                    <th># of Records</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.intelliscrubDbs.map((db) => (
                    <tr key={db.id}>
                      <td>
                        {db.name}{" "}
                        {db.stateAuthorizationRequired && (
                          <span> (Contact Help Desk to modify)</span>
                        )}{" "}
                        {db.isPremium && <span>$</span>}{" "}
                        {db.usesDMA === 1 && <span>(also part of TPS)</span>}
                        {db.isPremium &&
                          db.id === 44 &&
                          data.cpa.litigator === "0" && <span>$</span>}{" "}
                      </td>
                      <td>{db.updateFrequency}</td>
                      <td>{db.lastUpdatedOnSystem.trim()}</td>
                      <td>{db.recordCount.toLocaleString()}</td>
                      <td>
                        <select
                          {...register(`status-${db.id}`, {
                            valueAsNumber: true,
                          })}
                          disabled={isDropdownDisabled(db)}
                          className="table-form-select form-select"
                        >
                          <option value="-1">
                            Disallow: Exempt, Never Scrub Against
                          </option>
                          {db.isBlockable && (
                            <option value="0">
                              Block: Unauthorized, Block All Numbers
                            </option>
                          )}
                          {db.isBlockable && (
                            <option value="4">
                              Block Non-EBR: Unauthorized, Block All Except EBRs
                            </option>
                          )}
                          <option value="1">
                            Allow as Optional: Authorized, Allow Project to
                            Select
                          </option>
                          <option value="2">
                            Scrub by Default: Authorized, Allow Project to
                            Deselect
                          </option>
                          <option value="3">
                            Require: Authorized, Always Scrub Against
                          </option>
                        </select>
                      </td>
                    </tr>
                  ))}

                  <tr className="p-5">
                    <td colSpan={5}>
                      <button
                        type="button"
                        className="btn btn-secondary m-2"
                        onClick={() => handleUpdateAll(3)}
                      >
                        Require All
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary m-2"
                        onClick={() => handleUpdateAll(2)}
                      >
                        Scrub By Default All
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary m-2"
                        onClick={() => handleUpdateAll(1)}
                      >
                        Allow as Optional All
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary m-2"
                        onClick={handleBlockAll}
                      >
                        Block All
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary m-2"
                        onClick={handleResetToPlanDefault}
                      >
                        Reset to Plan Default
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <button type="submit" className="btn btn-primary m-2">
                        {!saveInProgress ? (
                          "Update Settings"
                        ) : (
                          <SaveInProgress isVisible={saveInProgress} />
                        )}
                      </button>

                      <button
                        type="button"
                        className="btn btn-secondary m-2"
                        onClick={handleReset}
                      >
                        Discard Changes
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default IntelliScrubDatabases;
