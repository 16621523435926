import React from "react";

interface InfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  actionLabel?: string;
  onAction?: () => void;
  required?: boolean;
}
/**
 * A modal component for displaying informational messages with optional action buttons.
 *
 * @component
 * @example
 * ```tsx
 * <InfoModal
 *   isOpen={showModal}
 *   onClose={() => setShowModal(false)}
 *   title="Important Notice"
 *   message="Please review the changes"
 *   actionLabel="Continue"
 *   onAction={() => handleContinue()}
 * />
 * ```
 *
 * @param props
 * @param props.isOpen - Controls the visibility of the modal
 * @param props.onClose - Callback function triggered when closing the modal
 * @param props.title - The title text shown in the modal header
 * @param props.message - The main message content of the modal
 * @param props.actionLabel - Optional label for the action button
 * @param props.onAction - Optional callback function triggered when action button is clicked
 * @param props.required - If true, removes the close button and forces user to use action button
 */

const InfoModal: React.FC<InfoModalProps> = ({
  isOpen,
  onClose,
  title,
  message,
  actionLabel,
  onAction,
  required = false,
}) => {
  if (!isOpen) return null;

  return (
    <>
      <div className="modal-backdrop show" />
      <div
        className="modal show d-block d-flex align-items-center justify-content-center"
        tabIndex={-1}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              {!required && (
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                />
              )}
            </div>
            <div className="modal-body">
              <div className="d-flex align-items-start">
                <i className="bi bi-exclamation-triangle-fill text-warning me-3 fs-1"></i>
                <div>
                  <p className="mb-0">{message}</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {!required && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  Close
                </button>
              )}
              {actionLabel && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onAction}
                >
                  {actionLabel}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoModal;
