import { useState, useEffect } from "react";
import { callApi, HttpMethod } from "src/services/apiService";

import CGNavBar from "src/pages/ComplianceGuide/NavBar";
import Paywall from "src/pages/ComplianceGuide/Paywall";
import Loading from "src/components/Loading";
import BackToTop from "src/components/BackToTop";
import ICpaSection from "src/interfaces/ICpaSection";
import { useLayoutContext } from "src/contexts/LayoutContext";

type ComplianceGuideLayoutProps = {
  children: React.ReactNode;
};

const ComplianceGuideLayout: React.FC<ComplianceGuideLayoutProps> = ({
  children,
}) => {
  const [access, setAccess] = useState<ICpaSection>();
  const [isLoading, setIsLoading] = useState(true);
  const [hasComplianceGuide, setHasComplianceGuide] = useState(false);
  useEffect(() => {
    const fetchComplianceGuideAccess = async () => {
      setIsLoading(true);
      try {
        setHasComplianceGuide(false);
        const apiUrl = `ComplianceGuide/HasComplianceGuide`;
        const apiResponse = await callApi(apiUrl, HttpMethod.GET);
        const cpaSection = apiResponse?.cpa;
        if (!cpaSection)
          throw new Error(
            "Invalid API response from ComplianceGuide/HasComplianceGuide",
          );
        setAccess(cpaSection);
        setHasComplianceGuide(cpaSection.complianceGuide ?? false);
        if (process.env.NODE_ENV === "development") {
          // Uncomment to test
          //setHasComplianceGuide(false);
        }
      } catch (error) {
        console.error("Failed to check if user has Complaince Guide:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchComplianceGuideAccess();
  }, []);

  const { heading, tagline } = useLayoutContext();

  return (
    <>
      <div className="d-flex">
        <CGNavBar />
        <BackToTop />
        <div className="container-fluid maint-content show-scroll-back-to-top">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {/* Redirect user to Paywall if they lack Compliance Guide access but permissions are defined */}
              {!hasComplianceGuide && access && (
                <Paywall isAdmin={access.isAdmin} />
              )}
              <main>
                <div id="compGuideMenuBtn">
                  <button
                    aria-controls="mobileCompGuideMenuNav"
                    className="btn btn-primary submenu-cta"
                    data-bs-target="#mobileCompGuideMenuNav"
                    data-bs-toggle="offcanvas"
                    type="button"
                  >
                    <i className="bi bi-card-list subnav-menu"></i>{" "}
                    <span className="submenu-header">
                      Compliance Guide Menu
                    </span>
                  </button>
                </div>
                <div className="compliance-guid-main-container">
                  <div>
                    <div className="col pagetitle">{heading}</div>

                    <div className="row w-100">
                      <div className="col">
                        <p>{tagline}</p>
                      </div>
                    </div>

                    {children}
                  </div>
                </div>
              </main>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ComplianceGuideLayout;
