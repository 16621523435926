import React, { useState } from "react";

interface CollapsibleCardProps {
  title: string;
  icon?: string;
  defaultExpanded?: boolean;
  children: React.ReactNode;
  className?: string;
}

const CollapsibleCard: React.FC<CollapsibleCardProps> = ({
  title,
  icon,
  defaultExpanded = true,
  children,
  className = "mb-3",
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  return (
    <div className={`card ${className}`}>
      <div
        className="card-header d-flex justify-content-between align-items-center cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ cursor: "pointer" }}
      >
        <h6 className="mb-0 d-flex align-items-center">
          {icon && <i className={`bi ${icon} me-2`}></i>}
          {title}
        </h6>
        <i className={`bi bi-chevron-${isExpanded ? "up" : "down"}`}></i>
      </div>
      {isExpanded && <div className="card-body">{children}</div>}
    </div>
  );
};

export default CollapsibleCard;
