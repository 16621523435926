import React from "react";
import { Link, useLocation } from "react-router-dom";
import SiteLayout from "./SiteLayout";
import styles from "./LogoCallLayout.module.css";
import SVGForPage from "src/components/SVGForPage";
import Breadcrumbs from "src/pages/Main/Breadcrumbs";

type LogoCallLayoutProps = {
  children: React.ReactNode;
};

const LogoCallLayout: React.FC<LogoCallLayoutProps> = ({ children }) => {
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === path ? styles.active : "";
  };

  const menuItems = [
    {
      path: "/logocall/enterprise",
      title: "Manage Enterprise",
      icon: "enterprise",
    },
    {
      path: "/logocall/display-records",
      title: "Display Records",
      icon: "display-records",
    },
    {
      path: "/logocall/reporting",
      title: "LogoCall Performance",
      icon: "reporting",
    },
  ];

  // Get current page title for breadcrumb
  const currentPage =
    menuItems.find((item) => item.path === location.pathname)?.title || "";

  return (
    <div className={styles.container}>
      {/* Navbar */}
      <nav className={styles.sidebar}>
        <ul className={styles.nav}>
          {menuItems.map((item) => (
            <li key={item.path}>
              <Link
                to={item.path}
                className={`${styles.navLink} ${isActive(item.path)}`}
              >
                <SVGForPage pageKeyword={item.icon} />
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      {/* Main content */}
      <main className={styles.content}>
        <div className={styles.pageHeader}>
          <h1>{currentPage}</h1>
          <Breadcrumbs />
        </div>
        {children}
      </main>
    </div>
  );
};

export default LogoCallLayout;
