import { useState, useEffect } from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import AppVerification from "./MFA/AppVerification";
import { formatPhoneNumber } from "src/utils/formatUtils";
import Loading from "src/components/Loading";
import IMfaSettings, { ITrustedDevice } from "src/interfaces/IMfaSettings";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";
import { formatCardDateTime } from "src/utils/formatUtils";
import "./Profile.module.css";

const Profile = () => {
  const { isSysadmin } = useAppSelector(selectAuth);
  const [data, setData] = useState<IMfaSettings>();
  const [trustedDevices, setTrustedDevices] = useState<ITrustedDevice[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [phoneConsent, setPhoneConsent] = useState(false);

  // Modal states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalActionLabel, setModalActionLabel] = useState("");
  const [modalPlaceholder, setModalPlaceholder] = useState("");
  const [modalError, setModalError] = useState("");

  useEffect(() => {
    fetchSettings();
    fetchTrustedDevices();
  }, []);

  async function fetchSettings() {
    try {
      const apiUrl = `mfa`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      setData(response);

      setShowEmail((response.mfaOptions & 1) !== 0);
      setShowPhone((response.mfaOptions & 2) !== 0);
      setShowApp((response.mfaOptions & 4) !== 0);
      // Initialize state based on API data
      setIsMfaEnabled(response.isEnabled);
      setPhoneConsent(response.phoneEnabled);
      if (response.mfaRequired) {
        setIsMfaEnabled(true);
      }
    } catch (error) {
      console.error("Failed to fetch MFA settings:", error);
    }
  }

  async function fetchTrustedDevices() {
    try {
      const apiUrl = `mfa/TrustedDevices`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      setTrustedDevices(response);
    } catch (error) {
      console.error("Failed to fetch trusted devices:", error);
    }
  }

  const deleteTrustedDevice = async (deviceId: number) => {
    if (
      !window.confirm("Are you sure you want to remove this trusted device?")
    ) {
      return;
    }

    try {
      const apiUrl = `mfa/TrustedDevices/${deviceId}`;
      await callApi(apiUrl, HttpMethod.DELETE);

      setTrustedDevices((prevDevices) =>
        prevDevices
          ? prevDevices.filter((device) => device.id !== deviceId)
          : [],
      );

      alert("Device removed successfully!");
    } catch (error) {
      console.error("Failed to remove trusted device:", error);
      alert("Failed to remove trusted device. Please try again.");
    }
  };

  const toggleMfa = async (value: boolean) => {
    if (!data?.emailEnabled && !data?.phoneEnabled && !data?.appEnabled) {
      alert("You need to setup at least 1 type of MFA before enabling MFA.");
      return;
    }

    try {
      const apiUrl = `mfa/enabledisable?type=mfa&value=${value}`;
      await callApi(apiUrl, HttpMethod.POST);

      fetchSettings();
    } catch (error) {
      console.error(`Failed to toggle MFA:`, error);
      alert(`Failed to ${value ? "enable" : "disable"} MFA. Please try again.`);
    }
  };

  const closeModal = () => {
    fetchSettings();
    setIsModalOpen(false);
    setModalError("");
    setInputValue("");
  };

  const handleSubmit = async () => {
    let formattedInput = inputValue.trim();

    if (modalType.toLowerCase() === "changeemail") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formattedInput)) {
        setModalError("Please enter a valid email address");
        return;
      }
    }

    if (modalType.toLowerCase() === "changephone") {
      formattedInput = formattedInput.replace(/\D/g, "");

      if (formattedInput.length !== 10) {
        setModalError("Please enter a valid 10-digit phone number.");
        return;
      }
    }

    const code = formattedInput;
    let apiUrl = "";
    let requestBody = null;

    switch (modalType.toLowerCase()) {
      case "email":
        apiUrl = `mfa/VerifyCode`;
        requestBody = {
          loginId: "",
          type: "email",
          code: code,
          deviceInfo: "",
          deviceId: "",
          trustDevice: false,
        };
        break;
      case "phone":
        apiUrl = `mfa/VerifyCode`;
        requestBody = {
          loginId: "",
          type: "phone",
          code: code,
          deviceInfo: "",
          deviceId: "",
          trustDevice: false,
        };

        break;
      case "changeemail":
        apiUrl = `mfa/ChangeEmail?email=${code}`;
        break;
      case "changephone":
        apiUrl = `mfa/ChangePhone?phone=${code}`;
        break;
      default:
      //Update error message here
    }
    if (apiUrl != "") {
      try {
        const response = await callApi(apiUrl, HttpMethod.POST, requestBody);
        if (response && response.type.toLowerCase() === "success") {
          //alert(`${modalType} verified successfully!`);
          setModalError("");
          closeModal();
        } else if (response && response.type.toLowerCase() === "failed") {
          setModalError(response.message);
        }
      } catch (error) {
        console.error(`Failed to verify ${modalType}:`, error);
        setModalError(`Failed to verify ${modalType}. Please try again.`);
      }
    }
  };

  const verifyEmail = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `mfa/SendEmailVerification`;
      const response = await callApi(apiUrl, HttpMethod.POST);
      if (response && response.type.toLowerCase() === "success") {
        showModal(
          "email",
          "Verification Email Sent",
          "Verify",
          "Enter email verification code",
        );
      } else if (response && response.type.toLowerCase() === "error") {
        alert(response.error);
      }
    } catch (error) {
      console.error(`Failed to verify ${modalType}:`, error);
      alert(`Failed to verify ${modalType}. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyPhone = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `mfa/SendPhoneVerification`;
      const response = await callApi(apiUrl, HttpMethod.POST);
      if (response && response.type.toLowerCase() === "success") {
        showModal(
          "phone",
          "Verification Code Sent",
          "Verify",
          "Enter verification code from your phone",
        );
      } else if (response && response.type.toLowerCase() === "error") {
        alert(response.error);
      }
    } catch (error) {
      console.error(`Failed to verify ${modalType}:`, error);
      alert(`Failed to verify ${modalType}. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyApp = () => {
    setModalType("app");
    setIsModalOpen(true);
  };

  const showModal = (
    type: string,
    title: string,
    actionLabel: string,
    placeholder: string,
  ) => {
    setModalType(type);
    setModalTitle(title);
    setModalActionLabel(actionLabel);
    setModalPlaceholder(placeholder);
    setIsModalOpen(true);
  };

  const setDefaultMFA = async (type: string) => {
    try {
      const apiUrl = `mfa/SetDefaultMFA?type=${type}`;
      const response = await callApi(apiUrl, HttpMethod.POST);
      if (response && response.type.toLowerCase() === "success") {
        fetchSettings();
      } else if (response && response.type.toLowerCase() === "error") {
        alert(response.error);
      }
    } catch (error) {
      console.error(`Failed to set default ${type}:`, error);
      alert(`Failed to set ${type} as default. Please try again.`);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="container-fluid">
        <h1>Profile</h1>
        <div className="card pb-3" style={{ maxWidth: "825px" }}>
          <div className="card-header">Multi-Factor Authentication</div>
          <div className="card-body">
            {/* Enable/Disable MFA */}
            <div className="row align-items-center ms-1 mb-3">
              Enhance your online security by adding an extra layer of
              authentication to your account.
            </div>
            <div className="row align-items-center mb-3">
              <div className="col-md-3 col-4">
                <button
                  className={`btn ${isMfaEnabled ? "btn-warning" : "btn-primary"}`}
                  disabled={
                    (isSysadmin && isMfaEnabled) ||
                    data?.mfaRequired ||
                    (!data?.emailEnabled &&
                      !data?.phoneEnabled &&
                      !data?.appEnabled)
                  }
                  onClick={() => toggleMfa(!isMfaEnabled)}
                >
                  {isMfaEnabled ? "Disable" : "Enable"} MFA
                </button>
              </div>
              {data?.mfaRequired && (
                <div className="col-md-9 col-8 text-danger">
                  MFA is required for this account. You cannot disable MFA.
                </div>
              )}
              {isSysadmin && isMfaEnabled && (
                <div className="col-md-9 col-8 text-danger">
                  MFA is required for sysAdmin accounts. You cannot disable MFA.
                </div>
              )}
              {!data?.emailEnabled &&
                !data?.phoneEnabled &&
                !data?.appEnabled && (
                  <div className="col-md-9 col-8 text-danger">
                    You need to setup at least 1 type of MFA
                    {!isSysadmin && (
                      <span className="text-danger">
                        {" "}
                        before enabling MFA on this account.
                      </span>
                    )}
                  </div>
                )}
            </div>

            <div className="d-flex flex-wrap justify-content-left gap-3">
              {showEmail && (
                <div className="card shadow-sm" style={{ width: "250px" }}>
                  <div className="card-header fw-bold">
                    <i className="bi bi-envelope me-2"></i>Email
                  </div>
                  <div className="card-body">
                    <p className="text-muted">
                      {data?.email || "No email provided"}
                      <button
                        className="btn btn-sm btn-outline-primary ms-3"
                        onClick={() =>
                          showModal(
                            "changeEmail",
                            "Change Account Email",
                            "Change",
                            "Enter updated account email",
                          )
                        }
                      >
                        <>
                          <i className="bi bi-pencil me-1"></i>
                          Edit
                        </>
                      </button>
                    </p>
                  </div>
                  <div className="card-footer text-center">
                    {data?.emailEnabled ? (
                      data?.defaultMethod === "email" ? (
                        <button className="btn btn-success w-100" disabled>
                          Primary
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary w-100"
                          onClick={() => setDefaultMFA("email")}
                        >
                          Set as Primary
                        </button>
                      )
                    ) : (
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => verifyEmail()}
                      >
                        Setup
                      </button>
                    )}
                  </div>
                </div>
              )}

              {showPhone && (
                <div className="card shadow-sm" style={{ width: "250px" }}>
                  <div className="card-header fw-bold">
                    <i className="bi bi-phone me-2"></i>Phone
                  </div>
                  <div className="card-body">
                    <p className="text-muted">
                      {data?.phone
                        ? formatPhoneNumber(data.phone)
                        : "No phone number provided"}
                      <button
                        className="btn btn-sm btn-outline-primary ms-3"
                        onClick={() =>
                          showModal(
                            "changePhone",
                            "Change Account Phone",
                            "Change",
                            "Enter updated phone number",
                          )
                        }
                      >
                        <>
                          <i className="bi bi-pencil me-1"></i>
                          Edit
                        </>
                      </button>
                      <div className="pt-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="phoneConsent"
                          checked={phoneConsent}
                          onChange={(e) => setPhoneConsent(e.target.checked)}
                          disabled={data?.phoneEnabled}
                        ></input>
                        <label
                          htmlFor="phoneConsent"
                          className="d-inline ps-2 text-muted"
                        >
                          <small>
                            I agree to receive SMS verification codes from
                            Contact Center Compliance. Message &amp; data rates
                            may apply.
                          </small>
                        </label>
                      </div>
                    </p>
                  </div>
                  <div className="card-footer text-center">
                    {data?.phoneEnabled ? (
                      data?.defaultMethod === "phone" ? (
                        <button className="btn btn-success w-100" disabled>
                          Primary
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary w-100"
                          onClick={() => setDefaultMFA("phone")}
                        >
                          Set as Primary
                        </button>
                      )
                    ) : (
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => verifyPhone()}
                        disabled={!phoneConsent}
                      >
                        Setup
                      </button>
                    )}
                  </div>
                </div>
              )}

              {showApp && (
                <div className="card shadow-sm" style={{ width: "250px" }}>
                  <div className="card-header fw-bold">
                    <i className="bi bi-shield-lock me-2"></i>Authenticator
                  </div>
                  <div className="card-body">
                    <p className="text-muted">
                      Set up with any authenticator app like Google
                      Authenticator, Microsoft Authenticator, or 1Password
                    </p>
                  </div>
                  <div className="card-footer text-center">
                    {data?.appEnabled ? (
                      data?.defaultMethod === "app" ? (
                        <button className="btn btn-success w-100" disabled>
                          Primary
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary w-100"
                          onClick={() => setDefaultMFA("app")}
                        >
                          Set as Primary
                        </button>
                      )
                    ) : (
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => verifyApp()}
                      >
                        Setup
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {data?.appEnabled && trustedDevices && (
          <div className="card mt-4 mb-4" style={{ maxWidth: "825px" }}>
            <div className="card-header fw-bold">Trusted Devices</div>
            <div className="card-body">
              <p>
                Manage devices that can access your account without MFA
                verification.
              </p>

              {trustedDevices.length > 0 ? (
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th>Device OS</th>
                      <th>Browser</th>
                      <th>Expiration Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trustedDevices.map((device) => {
                      const deviceInfo = device.deviceInfo ?? "";
                      const parts = deviceInfo.split(" | ");
                      const server = parts[0] || "Unknown";
                      const browser = parts[1] || "Unknown";

                      return (
                        <tr key={device.id}>
                          <td>{server}</td>
                          <td>{browser}</td>
                          <td>{formatCardDateTime(device.expirationDate)}</td>
                          <td>
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => deleteTrustedDevice(device.id)}
                            >
                              <i className="bi bi-trash"></i> Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p className="text-muted">No trusted devices found.</p>
              )}
            </div>
          </div>
        )}

        {isModalOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <div
              style={{
                backgroundColor: "var(--bs-body-bg)",
                padding: "20px",
                borderRadius: "8px",
                width: modalType === "app" ? "800px" : "400px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              {modalType === "app" ? (
                <AppVerification onClose={closeModal} />
              ) : (
                <>
                  <h5>{modalTitle}</h5>
                  <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => {
                      if (modalType.toLowerCase() === "changephone") {
                        setInputValue(formatPhoneNumber(e.target.value));
                      } else {
                        setInputValue(e.target.value);
                      }
                    }}
                    placeholder={modalPlaceholder}
                    className="form-control"
                    style={{
                      width: "100%",
                      padding: "10px",
                      margin: "20px 0",
                    }}
                  />
                  {modalError && (
                    <div style={{ color: "red" }}>{modalError}</div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <button onClick={closeModal} className="btn btn-secondary">
                      Cancel
                    </button>
                    <button onClick={handleSubmit} className="btn btn-primary">
                      {modalActionLabel}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Profile;
