import { useState, useEffect, useCallback } from "react";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import axios, { AxiosProgressEvent } from "axios";
import { useUploadListContext } from "src/contexts/UploadListContext";
import styles from "./UploadFile.module.css";
import Loading from "src/components/Loading";
import { HttpMethod, callApi } from "src/services/apiService";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";
import Warning from "src/components/Warning";
import ProgressBar from "src/components/ProgressBar";
import FileUploadContainer from "src/components/FileUploadContainer";

const UploadFile: React.FC = () => {
  const { csrfToken } = useAppSelector(selectAuth);
  const { campaignId } = useUploadListContext();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showComponent, setShowComponent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showInstructions, setShowInstructions] = useState(true);

  useEffect(() => {
    //AK - Check access
    const fetchUploadFileAccess = async () => {
      try {
        const apiUrl = `sessions/ShowUpload?campaignId=${campaignId}`;
        const response = await callApi(apiUrl, HttpMethod.GET);
        if (response.message === "Yes") {
          setShowComponent(true);
        }
      } catch (error) {
        console.error("Failed to fetch project data:", error);
      }
    };

    setIsLoading(true);
    if (campaignId !== "") {
      fetchUploadFileAccess();
    }
    setIsLoading(false);
  }, [campaignId]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      setErrorMessage(""); // Clear any previous errors

      const formData = new FormData();
      formData.append("campaignId", campaignId);
      formData.append("handler", "scrub_upload");
      formData.append("CSRFToken", csrfToken);
      formData.append("files[]", file);

      // Send the request
      axios({
        method: "post",
        url: "https://www.dncscrub.com/app/main/XFileCallbackPOST",
        data: formData,
        withCredentials: true, // This will ensure cookies are sent with the request - Tcl App needs this!
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const total = progressEvent.total || progressEvent.loaded; // Safe fallback
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / total,
          );
          setUploadProgress(percentCompleted);
        },
      })
        .then((response) => {
          console.log("File uploaded:", response.data);
          setUploadProgress(0);
        })
        .catch((error) => {
          console.error(
            "There was an error uploading the file. Likley the loginId cookie axios is sending isn't the same as the loginId in Local Storage. Unsure exact cases this bug intermittently occurs",
            error,
          );
          setUploadProgress(0);
          setErrorMessage(
            error.response?.data?.message ||
              "There was an error uploading your file. Please try to logout and login again and then re-upload the file. If error continues, contact support@dnc.com.",
          );
        });
    },
    [campaignId, csrfToken],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });
  if (isLoading) {
    return <Loading />;
  }

  if (!showComponent) {
    return null;
  }
  return (
    <>
      <h2>Do Not Call Scrub</h2>
      {errorMessage && <Warning message={errorMessage} />}
      <div className="row">
        {/* Upload control */}
        <div
          className={`col-md-12 ${showInstructions ? "col-lg-6" : "col-lg-6"}`}
        >
          <div className="d-flex gap-2 mt-4 flex-wrap">
            <FileUploadContainer
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              isDragActive={isDragActive}
              title="UPLOAD LIST"
              iconClassName="bi-box-arrow-up"
            />
          </div>
        </div>

        {/* File format instructions */}
        <div className="col-md-12 col-lg-6">
          <div className="card rounded">
            <div
              className="card-header d-flex justify-content-between align-items-center p-3 cursor-pointer"
              onClick={() => setShowInstructions(!showInstructions)}
              style={{ cursor: "pointer" }}
            >
              <h5 className="mb-0 d-flex align-items-center">
                <i className="bi bi-info-circle me-2"></i>
                File Format Instructions
              </h5>
              <i
                className={`bi bi-chevron-${showInstructions ? "up" : "down"}`}
              ></i>
            </div>
            {showInstructions && (
              <div className="card-body p-3">
                The first column of the file should be the phone number in 10
                digit format. You may upload either text or zipped text files.
                Textfiles must end in .csv or .txt. Need help? Contact our Help
                Desk.
              </div>
            )}
          </div>
        </div>
      </div>

      <button
        className="download-file-available mt-3 d-none"
        onClick={() => onDrop([])}
      >
        Upload Data
      </button>

      {uploadProgress > 0 && <ProgressBar progress={uploadProgress} />}
    </>
  );
};

export default UploadFile;
