import React, { useState } from "react";
import { useController, Control } from "react-hook-form";

interface EmailTextareaProps {
  name: string;
  control: Control<any>;
  disabled?: boolean;
}

// Utility function for email validation so can be
// used by both the validate property of react-hook-forms
// and the onBlur event handler
const validateEmails = (value: string) => {
  // If no email, its valid
  if (!value) {
    return { isValid: true, errorMessage: "" };
  }

  // Check for trailing separators
  if (/[\n,;]$/.test(value)) {
    return {
      isValid: false,
      errorMessage:
        "Please remove the trailing separator (comma, semicolon, or newline) after the last email address.",
    };
  }

  const emails = value
    .split(/[\n,;]/)
    .map((email) => email.trim())
    .filter((email) => email !== "");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const allValid = emails.every((email) => emailRegex.test(email));

  return {
    isValid: allValid,
    errorMessage: allValid
      ? ""
      : "Please enter valid email addresses separated by commas or newlines.",
  };
};

const EmailTextarea: React.FC<EmailTextareaProps> = ({
  name,
  control,
  disabled,
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules: {
      validate: (value: string) => {
        const { isValid, errorMessage } = validateEmails(value);
        return isValid || errorMessage;
      },
    },
  });
  const [emailError, setEmailError] = useState<string | null>(null);

  const handleBlur = (value: string) => {
    const { errorMessage } = validateEmails(value);
    setEmailError(errorMessage);
  };

  return (
    <>
      <textarea
        {...field}
        onBlur={(e) => {
          handleBlur(e.target.value);
          field.onBlur();
        }}
        onChange={(e) => {
          setEmailError(null); // Clear local error
          field.onChange(e); // Update form state
        }}
        className={`form-control ${
          emailError || fieldState.error ? "is-invalid" : ""
        }`}
        disabled={disabled}
      />
      {emailError && <span className="text-danger">{emailError}</span>}
      {fieldState.error && (
        <span className="text-danger">{fieldState.error.message}</span>
      )}
    </>
  );
};

export default EmailTextarea;
