/*
Display the breadcrumb which for the Main area is both:
Project Name » Campaign Name 
and then *also* Section / PageName
*/
import { useUploadListContext } from "src/contexts/UploadListContext";
import { useLayoutContext } from "src/contexts/LayoutContext";
import { useLocation } from "react-router-dom";
import styles from "./Breadcrumbs.module.css";
import parse from "html-react-parser";

const capitalizeWords = (input: string) => {
  return input
    .split(" ") // Split the input into words by space
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(" "); // Join the words back into a single string
};

const Breadcrumbs = () => {
  const { parentProjectName, projectName, campaignName } =
    useUploadListContext();
  const location = useLocation();

  // Get from a URL like /main/upload-list/35138 "Main"
  // and a URL like /compliance-guide/intro
  // "Compliance Guide"
  const sectionName = capitalizeWords(
    location.pathname.split("/")[1].replace("-", " "),
  );

  const isMainSection = sectionName.toLowerCase() === "main";

  const pageName = useLayoutContext().PageTitle;

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="mt-2 mb-3">
        <h6
          className={`d-flex flex-wrap justify-content-between gap-2 ${styles["breadcrumb"]}`}
        >
          <div className={`d-flex align-items-center`}>
            {isMainSection && parentProjectName && (
              <>
                <i className={`ms-1 me-1 ${styles["folder-icon"]}`}></i>
                <span>{parse(parentProjectName)}</span>
                <span className="ms-1 me-1"> » </span>
              </>
            )}
            {isMainSection && projectName && (
              <>
                <i className={`ms-1 me-1 ${styles["folder-icon"]}`}></i>
                <span>{parse(projectName)}</span>
                <span className="ms-1 me-1"> » </span>
                <span>{parse(campaignName)}</span>
              </>
            )}
          </div>
          <div className={`d-none d-md-flex align-items-center`}>
            <span>{parse(sectionName)}</span>
            <span className="ms-1 me-1"> / </span>
            <button className="btn btn-link p-0 fw-500" onClick={reloadPage}>
              {parse(pageName)}
            </button>
          </div>
        </h6>
      </div>
    </>
  );
};

export default Breadcrumbs;
