import { useState, ReactNode, ComponentType, useEffect } from "react";
import SiteLayout from "src/layouts/SiteLayout";
import LayoutContext from "src/contexts/LayoutContext";

interface WithLayoutProps {
  Component: ComponentType;
  Layout?: ComponentType<{ children: ReactNode }>;
  SubLayout?: ComponentType<{ children: ReactNode }>;
  PageTitle?: string;
}

// Note this was at first a function that returned a function
// but refactoring it a component to improve readability
const WithLayout: React.FC<WithLayoutProps> = ({
  Component,
  Layout = SiteLayout,
  SubLayout,
  PageTitle = "",
}) => {
  const [heading, setHeading] = useState("");
  const [tagline, setTagline] = useState("");
  const [currentPageTitle, setPageTitle] = useState(PageTitle);

  useEffect(() => {
    setPageTitle(PageTitle); // Set the page title once on mount
    document.title = `DNCScrub: ${PageTitle}`;
  }, [PageTitle, currentPageTitle]);

  // Wrap Component with a SubLayout if provided
  // I did this as Comp Guide and LogoCall have their own
  // layout components and I wanted to refactor those components
  // to be under <SiteLayout />
  // so the top nav bar component doesn't re-render
  // when switching to LogoCall or Comp Guide.
  // So structure is:
  // <App />
  // __<SiteLayout />
  // ____<Main />
  // ______<Upload list />
  // ______<IDNC />
  // ______etc.
  // ____<LogoCallLayout />
  // _____<ManageEnterprise />
  // _____etc.
  // ____<CGLayout />
  // _____<ComplianceGuide />
  // _____etc.

  const ContentWithSubLayout = SubLayout ? (
    <SubLayout>
      <Component />
    </SubLayout>
  ) : (
    <Component />
  );

  return (
    <LayoutContext.Provider
      value={{
        heading,
        tagline,
        PageTitle: currentPageTitle,
        setHeading,
        setTagline,
        setPageTitle,
      }}
    >
      <Layout>{ContentWithSubLayout}</Layout>
    </LayoutContext.Provider>
  );
};

export default WithLayout;
