import { useEffect, useState, useCallback } from "react";
import * as React from "react";
import { callApi, HttpMethod } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import Warning from "src/components/Warning";
import { CombinedTCPADataDTO } from "./Common";
import { ReassignedScrubItem } from "./ReassignedScrubItem";
import Styles from "./ReassignedScrubListItem.module.css";
import { formatCardDateTime } from "src/utils/formatUtils";
import Pagination from "src/components/Pagination";

const ITEMS_PER_PAGE = 25;

const ReassignedScrubListItem: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { campaignId } = useUploadListContext();
  const [sessions, setSessions] = useState<CombinedTCPADataDTO[]>([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [shouldStopInterval, setShouldStopInterval] = useState(false);
  const [showNoAccess, setShowNoAccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const refreshInterval =
    (Number(import.meta.env.VITE_APP_REFRESH_INTERVAL_IN_SECONDS) || 5) * 1000;

  const fetchSessions = useCallback(
    async (isInitialLoad = false) => {
      try {
        if (!campaignId) {
          setWarningMessage("There was an issue getting the campaign details.");
          setShouldStopInterval(true);
          setShowNoAccess(true);
          return;
        }

        setWarningMessage("");

        const apiUrl = `sessions/GetTCPAAuthoritySessions?campaignId=${campaignId}`;
        const data = await callApi(apiUrl, HttpMethod.GET);

        if (data.length > 0) {
          const fetchedSessions: CombinedTCPADataDTO[] = data;
          setSessions(fetchedSessions);
          setShouldStopInterval(false);
          setShowNoAccess(false);
        }
      } catch (error) {
        setWarningMessage(
          error instanceof Error
            ? error.message
            : "An unexpected error occurred",
        );
      }
    },
    [campaignId],
  );

  useEffect(() => {
    setSessions([]);
  }, [campaignId]);

  useEffect(() => {
    if (campaignId) {
      setIsLoading(true);
      fetchSessions(true).finally(() => setIsLoading(false));

      const intervalId = setInterval(() => {
        if (!shouldStopInterval) {
          fetchSessions();
        } else {
          clearInterval(intervalId);
        }
      }, refreshInterval);

      return () => clearInterval(intervalId);
    }
  }, [campaignId, fetchSessions, refreshInterval, shouldStopInterval]);

  // Calculate pagination
  const totalPages = Math.ceil(sessions.length / ITEMS_PER_PAGE);
  const currentSessions = sessions.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE,
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {showNoAccess ? (
            <div>
              <i className="h1 text-danger bi bi-exclamation-triangle pe-3"></i>
              You do not have access to this campaign
            </div>
          ) : (
            <>
              <Warning message={warningMessage} />
              {sessions.length > 0 ? (
                <>
                  {currentSessions.map((session) => {
                    return (
                      <React.Fragment
                        key={
                          session.sessionData?.sessionId ||
                          session.errorData?.id
                        }
                      >
                        {session.sessionData && (
                          <ReassignedScrubItem
                            key={session.sessionData.sessionId}
                            session={session.sessionData}
                          />
                        )}
                        {session.errorData && (
                          <div
                            key={session.errorData.id}
                            className={`card mb-3 ${Styles["presession-error-card"]}`}
                          >
                            <div className="card-body d-flex gap-3 align-items-center">
                              <i className="h1 text-danger bi bi-exclamation-circle-fill"></i>
                              <h4 className="text-black">Error</h4>
                              <div className="d-flex flex-wrap">
                                <div className="col-12 col-md-6">
                                  <b>Error: </b>
                                  {session.errorData.errorMsg}
                                </div>
                                <div className="col-12 col-md-6">
                                  <b>File Details: </b>
                                  {session.errorData.originalFilename}
                                </div>
                                <div className="col-12 col-md-6">
                                  <b>Uploaded: </b>
                                  {formatCardDateTime(
                                    session.errorData.receivedOn ?? "",
                                  )}
                                </div>
                                <div className="col-12 col-md-6">
                                  <b>Received By: </b>
                                  {session.errorData.fullName}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}

                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              ) : (
                <div>
                  <p>{"No record found."}</p>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ReassignedScrubListItem;
