import React, { useEffect, useState, useCallback, ChangeEvent } from "react";
import {
  callApi,
  callApiWithOptions,
  HttpMethod,
} from "src/services/apiService";
import Loading from "src/components/Loading";
import { formatPhoneNumber } from "src/utils/formatUtils";
import {
  IPagedPhoneNumbers,
  IPhoneNumber,
} from "src/interfaces/LogoCall/IPhoneNumbers";
import ConfirmationModal from "src/components/ConfirmationModal";
import FileUploadContainer from "src/components/FileUploadContainer";
import { useDropzone, FileRejection } from "react-dropzone";

interface PhoneNumberProps {
  isOpen: boolean;
  onClose: () => void;
  displayRecordId: string | null;
  enterpriseId: string;
  callingName: string;
}

const PhoneNumbers: React.FC<PhoneNumberProps> = ({
  isOpen,
  onClose,
  displayRecordId,
  enterpriseId,
  callingName,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [data, setData] = useState<IPagedPhoneNumbers<IPhoneNumber>>();
  const [phoneNumbers, setPhoneNumbers] = useState<IPhoneNumber[]>([]);
  const [pendingImportFile, setPendingImportFile] = useState<File | null>(null);
  const [showImportConfirmation, setShowImportConfirmation] = useState(false);
  const [pendingDeletePhoneNumber, setPendingDeletePhoneNumber] = useState<
    string | null
  >(null);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [page, setPage] = useState(1);
  const maxPhoneNumbersPerPage =
    Number(import.meta.env.VITE_APP_LOGOCALL_MAX_PHONE_NUMBERS_PER_PAGE) || 24;
  const maxPaginationLinks =
    Number(import.meta.env.VITE_APP_LOGOCALL_MAX_PAGINATION_LINKS) || 10;

  const getPagesToShow = (
    currentPage: number,
    totalPages: number,
  ): number[] => {
    if (totalPages <= maxPaginationLinks) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
    let startPage = currentPage - Math.floor(maxPaginationLinks / 2);
    let endPage =
      currentPage +
      Math.floor(maxPaginationLinks / 2) -
      (maxPaginationLinks % 2 === 0 ? 1 : 0);

    if (startPage < 1) {
      startPage = 1;
      endPage = maxPaginationLinks;
    }
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - maxPaginationLinks + 1;
    }
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i,
    );
  };

  const pagesToShow =
    data && data.totalPages ? getPagesToShow(page, data.totalPages) : [];

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    // Allow only numbers, +, (, ), -, and space.
    const filteredValue = rawValue.replace(/[^0-9+()\-\s]/g, "");
    const digits = filteredValue.replace(/\D/g, "");
    if (digits.length >= 10) {
      const normalized =
        digits.length === 10
          ? digits[0] === "1"
            ? digits
            : "1" + digits
          : digits.substring(0, 11);
      const formatted = `+${normalized[0]} (${normalized.substring(1, 4)}) ${normalized.substring(4, 7)}-${normalized.substring(7, 11)}`;
      setNewPhoneNumber(formatted);
    } else {
      setNewPhoneNumber(filteredValue);
    }
  };

  const handlePhoneNumberBlur = () => {
    const digits = newPhoneNumber.replace(/\D/g, "");
    if (digits.length !== 11 && digits.length !== 0) {
      setIsPhoneNumberValid(false);
    } else {
      setIsPhoneNumberValid(true);
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles[0]) {
      console.log("File dropped/selected:", acceptedFiles[0].name);
      setPendingImportFile(acceptedFiles[0]);
      setShowImportConfirmation(true);
    }
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      setFileErrorMessage("Please upload a valid CSV or TXT file.");
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "text/csv": [".csv"],
      "text/plain": [".txt"],
    },
  });

  const handleImportFile = async (replace: boolean) => {
    if (!pendingImportFile) return;
    setIsLoading(true);
    try {
      console.log("Importing file:", pendingImportFile.name);
      console.log("Replace:", replace);
      console.log("Display Record ID:", displayRecordId);
      console.log("Enterprise ID:", enterpriseId);

      const payload = {
        dirId: displayRecordId || "",
        enterpriseId: enterpriseId,
        replace: String(replace),
      };

      await callApiWithOptions({
        endpoint: "bcid/dir/importnumbers",
        method: HttpMethod.POST,
        body: payload,
        file: pendingImportFile,
      });

      fetchDisplayRecordData();
    } catch (error) {
      setWarningMessage("Failed to import phone numbers.");
      console.error("Error importing phone numbers:", error);
    } finally {
      setIsLoading(false);
      setShowImportConfirmation(false);
      setPendingImportFile(null);
    }
  };

  const handleDownload = () => {
    const apiUrl = `${import.meta.env.VITE_APP_API_URL}/bcid/dir/downloadnumbers?displayIdentityRecordId=${displayRecordId}`;
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = apiUrl;
    document.body.appendChild(iframe);
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 5000);
  };

  const fetchDisplayRecordData = useCallback(async () => {
    if (!displayRecordId) return;
    setIsLoading(true);
    try {
      setWarningMessage("");
      const apiUrl = `bcid/dir/phoneNumbers?dirId=${displayRecordId}&page=${page}&pageSize=${maxPhoneNumbersPerPage}&search=${searchTerm}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      setData(response);
      setPhoneNumbers(response.data || []);
    } catch (error) {
      setWarningMessage("Failed to fetch data.");
      console.error("Failed to fetch data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [displayRecordId, page, searchTerm]);

  useEffect(() => {
    if (displayRecordId) {
      fetchDisplayRecordData();
    }
  }, [displayRecordId, fetchDisplayRecordData]);

  useEffect(() => {
    setPage(1);
  }, [displayRecordId]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const handleAddPhoneNumber = async () => {
    const cleanedNumber = newPhoneNumber.replace(/\D/g, "");
    if (cleanedNumber.length !== 11) {
      setWarningMessage("Phone number must be 11 digits.");
      return;
    }
    setIsLoading(true);
    try {
      const payload = {
        dirId: displayRecordId,
        enterpriseId,
        phoneNumber: cleanedNumber,
      };
      await callApiWithOptions({
        endpoint: "bcid/dir/phoneNumbers",
        method: HttpMethod.POST,
        body: payload,
      });
      setNewPhoneNumber("");
      setWarningMessage("");
      fetchDisplayRecordData();
    } catch (error: any) {
      const message =
        error?.data?.message || error?.message || "Failed to add phone number.";
      setWarningMessage(message);
      console.error("Error adding phone number:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPage(1);
  };

  const handleDeletePhoneNumber = (phoneNumber: string) => {
    setPendingDeletePhoneNumber(phoneNumber);
    setShowDeleteConfirmation(true);
  };

  const confirmDeletePhoneNumber = async () => {
    if (!pendingDeletePhoneNumber) return;
    setIsLoading(true);
    try {
      const apiUrl = `bcid/dir/phoneNumbers`;
      const payload = {
        dirId: displayRecordId,
        enterpriseId,
        phoneNumber: pendingDeletePhoneNumber,
      };
      await callApi(apiUrl, HttpMethod.DELETE, payload);
      fetchDisplayRecordData();
    } catch (error) {
      setWarningMessage("Failed to delete phone number.");
      console.error("Error deleting phone number:", error);
    } finally {
      setIsLoading(false);
      setShowDeleteConfirmation(false);
      setPendingDeletePhoneNumber(null);
    }
  };

  const cancelDeletePhoneNumber = () => {
    setShowDeleteConfirmation(false);
    setPendingDeletePhoneNumber(null);
  };

  const nextPage = () => {
    if (data && page < data.totalPages) setPage(page + 1);
  };
  const prevPage = () => {
    if (page > 1) setPage(page - 1);
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="modal-backdrop show" />
      <div className="modal show d-block" tabIndex={-1}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Manage Phone Number for {callingName}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                aria-label="Close modal"
              ></button>
            </div>
            <div className="modal-body pt-0">
              <div className="p-2">
                Manage the telephone numbers that will be branded. You can add
                numbers individually or import multiple numbers at once using a
                CSV file. Use the search function to find specific numbers, and
                download the current list for reference. To remove a number,
                click the red ❌ icon next to it.
                <br />
                <small>
                  To upload phone numbers via CSV file, place one phone per
                  line. Download{" "}
                  <a
                    href="/fileformats/LogoCallUploadTemplate.csv"
                    download
                    style={{ display: "inline" }}
                  >
                    Upload Phone Numbers
                  </a>{" "}
                </small>
                template.
              </div>
              {warningMessage && (
                <div className="alert alert-warning">{warningMessage}</div>
              )}
              <div className="row mb-3 g-3">
                <div className="col-md-4">
                  <div className="row g-2 align-items-center mb-2">
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search phone number"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        maxLength={11}
                      />
                    </div>
                    <div className="col-auto">
                      <button
                        className="btn btn-outline-primary"
                        onClick={handleClearSearch}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="row g-2 align-items-center">
                    <div className="col">
                      <input
                        type="text"
                        className={`form-control ${!isPhoneNumberValid ? "is-invalid" : ""}`}
                        placeholder="+1 (___) ___-____"
                        value={newPhoneNumber}
                        onChange={handlePhoneNumberChange}
                        onBlur={handlePhoneNumberBlur}
                      />
                    </div>
                    <div className="col-auto">
                      <button
                        className="btn btn-outline-primary"
                        onClick={handleAddPhoneNumber}
                        disabled={
                          newPhoneNumber.replace(/\D/g, "").length !== 11
                        }
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row g-2 align-items-center">
                    <div className="col-9">
                      <FileUploadContainer
                        getRootProps={getRootProps}
                        getInputProps={getInputProps}
                        isDragActive={isDragActive}
                        title="Upload Phone Numbers"
                        iconClassName="bi-upload"
                      />
                      {fileErrorMessage && (
                        <div className="text-danger mt-2">
                          {fileErrorMessage}
                        </div>
                      )}
                      <ConfirmationModal
                        isVisible={showImportConfirmation}
                        message="Do you want to replace existing phone numbers? Click Replace to replace, or Append to add."
                        title="Import Confirmation"
                        confirmText="Replace"
                        cancelText="Append"
                        onConfirm={() => handleImportFile(true)}
                        onCancel={() => handleImportFile(false)}
                      />{" "}
                    </div>
                    <div className="col-3">
                      <button
                        className="btn btn-primary"
                        onClick={handleDownload}
                      >
                        Download Phone Numbers
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="d-flex flex-column"
                style={{
                  maxWidth: "750px",
                  minHeight: "300px",
                  maxHeight: "500px",
                  overflowY: "auto",
                }}
              >
                <div className="mb-3" style={{ overflowX: "hidden" }}>
                  <ConfirmationModal
                    isVisible={showDeleteConfirmation}
                    message="Are you sure you want to delete this phone number?"
                    title="Delete Confirmation"
                    confirmText="Yes"
                    cancelText="No"
                    onConfirm={confirmDeletePhoneNumber}
                    onCancel={cancelDeletePhoneNumber}
                  />
                  <label className="form-label">
                    <b>Telephone Numbers</b>
                  </label>
                  <div style={{ minHeight: "200px", position: "relative" }}>
                    {isLoading ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <Loading />
                      </div>
                    ) : (
                      <>
                        {phoneNumbers.length > 0 ? (
                          <div className="row row-cols-4 mt-2">
                            {phoneNumbers.map((number) => (
                              <div
                                key={number.key}
                                className="col d-flex justify-content-left align-items-center mb-2"
                              >
                                {formatPhoneNumber(number.value || "")}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  className="text-danger ps-2"
                                  onClick={() =>
                                    handleDeletePhoneNumber(number.value || "")
                                  }
                                >
                                  <line
                                    x1="3"
                                    y1="3"
                                    x2="21"
                                    y2="21"
                                    stroke="currentColor"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                  />
                                  <line
                                    x1="21"
                                    y1="3"
                                    x2="3"
                                    y2="21"
                                    stroke="currentColor"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p>No phone numbers found.</p>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {data && data.totalPages > 1 && (
                  <div
                    className="d-flex flex-wrap justify-content-center mt-3"
                    style={{ gap: "0.5rem" }}
                  >
                    <button
                      className="btn btn-secondary"
                      onClick={prevPage}
                      disabled={page === 1}
                    >
                      Previous
                    </button>
                    {pagesToShow.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setPage(pageNumber)}
                        className={`btn ${
                          pageNumber === page
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                    <button
                      className="btn btn-secondary"
                      onClick={nextPage}
                      disabled={page === data.totalPages}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-end">
                <button className="btn btn-secondary mt-3" onClick={onClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneNumbers;
