import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./app/store";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const container = document.getElementById("root")!;
const root = createRoot(container);

if (import.meta.env.VITE_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://891b24de1672aded3cbe4a3b3c5eecd0@o4507747315417088.ingest.us.sentry.io/4507747315679232",
    integrations: [
      // Adding this to so that Sentry registers console.error() as exceptions
      // and shows in their console of errors
      Sentry.captureConsoleIntegration({ levels: ["error"] }),

      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.breadcrumbsIntegration({
        console: true,
        dom: true,
        fetch: true,
        history: true,
        xhr: true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "webapi.dncscrub.com",
      // Disable for now as it causes CORs trouble
      ///^https:\/\/[a-zA-Z0-9]+\.dncscrub\.com/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.2, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.VITE_APP_ENV,
    // Gives details of why an operation is slow. Shows function name
    profilesSampleRate: 1.0,
  });
}

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </StrictMode>,
);

// Register service worker for PWA functionality
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      // Instead of immediately activating, show a message to the user
      console.log("New version available. Please refresh the page to update.");
      // You could show a toast notification here if you want
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// Disabling this for now as we have both Application Insights and Sentry
//reportWebVitals();
