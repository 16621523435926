import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import styles from "./ManageEnterprise.module.css";
import { callApiWithOptions, HttpMethod } from "src/services/apiService";
import Loading from "src/components/Loading";
import SaveInProgress from "src/components/SaveInProgress";
import { EnterpriseResponse } from "src/interfaces/LogoCall/IEnterprise";
import { NORTH_AMERICAN_REGIONS } from "src/data/us-states-and-can-provinces";
import { COUNTRIES } from "src/data/countries";
import { SlSelect, SlOption } from "@shoelace-style/shoelace/dist/react";

interface OSP {
  originatingServiceProviderId: string;
  isActive: boolean;
  ospName: string;
  spcs: string;
  updatedDate: string;
}

interface EnterpriseDetails {
  legalName: string;
  ein: string;
  primaryPhoneNumber: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  countryCode: string;
  industry: string;
  employeeCount: number;
  primaryContactName: string;
  primaryContactEmail: string;
  primaryContactPhoneNumber: string;
  dunsNumber: string;
  url: string;
  dba: string;
  callsPerMonth: number;
  industryId: number;
  ospIds: number[];
  orgType: string;
}

const formatEIN = (value: string) => {
  // Remove all non-digits
  const digits = value.replace(/\D/g, "");

  // Format as XX-XXXXXXX
  if (digits.length <= 2) return digits;
  return `${digits.slice(0, 2)}-${digits.slice(2, 9)}`;
};

const unformatEIN = (value: string) => {
  return value.replace(/\D/g, "");
};

const ManageEnterprise: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [enterpriseData, setEnterpriseData] =
    useState<EnterpriseResponse | null>(null);
  const [availableOSPs, setAvailableOSPs] = useState<OSP[]>([]);
  const [industries, setIndustries] = useState<
    { text: string; value: string }[]
  >([]);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<EnterpriseDetails>();

  const loadData = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Load OSPs
      const ospResponse = await callApiWithOptions({
        endpoint: "bcid/enterprise/osps",
        method: HttpMethod.GET,
      });
      // Filter for active OSPs only
      setAvailableOSPs(ospResponse.filter((osp: OSP) => osp.isActive));

      // Load enterprise data
      const response = await callApiWithOptions({
        endpoint: "bcid/enterprise",
        method: HttpMethod.GET,
        allowedErrorStatus: [404],
      });

      if (response.status === 404) {
        setError(
          "There was an issue loading the enterprise data. Please try again.",
        );
        return;
      }

      if (response.industries) {
        setIndustries(response.industries);
      }

      setEnterpriseData(response);

      const dto = response.dto || {};
      // Map the nested legalEntity data to form fields
      if (dto.legalEntity) {
        reset({
          legalName: dto.legalEntity.legalName,
          ein: dto.legalEntity.ein ? formatEIN(dto.legalEntity.ein) : "",
          primaryPhoneNumber: dto.legalEntity.primaryPhoneNumber,
          streetAddress: dto.legalEntity.streetAddress,
          city: dto.legalEntity.city,
          state: dto.legalEntity.state,
          zip: dto.legalEntity.zip?.trim(), // Remove trailing spaces
          countryCode: dto.legalEntity.countryCode?.trim(),
          industry: dto.legalEntity.industry || "",
          employeeCount: dto.legalEntity.employeeCount,
          primaryContactName: dto.legalEntity.primaryContactName,
          primaryContactEmail: dto.legalEntity.primaryContactEmail,
          primaryContactPhoneNumber: dto.legalEntity.primaryContactPhoneNumber,
          dunsNumber: dto.legalEntity.dunsNumber || "",
          url: dto.legalEntity.url,
          dba: dto.legalEntity.dba,
          callsPerMonth: dto.legalEntity.callsPerMonth,
          industryId: dto.legalEntity.industryId,
          ospIds: dto.ospIds || [],
          orgType: dto.orgType || "",
        });
      }
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An error occurred while loading enterprise data",
      );
      console.error("Error loading enterprise data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [reset]);

  const onSubmit = async (data: EnterpriseDetails) => {
    setSaveInProgress(true);
    console.log("Form submitted with data:", data);
    try {
      if (!enterpriseData?.dto) {
        console.error("Enterprise data is missing.");
        return;
      }

      const updatePayload = {
        enterpriseId: enterpriseData?.dto.enterpriseId,
        orgType: data.orgType,
        legalEntity: {
          legalEntityId: enterpriseData?.dto.legalEntity.legalEntityId,
          legalName: data.legalName,
          ein: unformatEIN(data.ein),
          primaryPhoneNumber: data.primaryPhoneNumber || null,
          streetAddress: data.streetAddress || null,
          city: data.city || null,
          state: data.state || null,
          zip: data.zip || null,
          countryCode: data.countryCode || null,
          employeeCount: data.employeeCount || null,
          primaryContactName: data.primaryContactName || null,
          primaryContactEmail: data.primaryContactEmail || null,
          primaryContactPhoneNumber: data.primaryContactPhoneNumber || null,
          dunsNumber: data.dunsNumber ? data.dunsNumber.trim() : null,
          url: data.url || null,
          dba: data.dba || null,
          callsPerMonth: data.callsPerMonth || null,
          industryId: data.industryId ? Number(data.industryId) : null,
        },
        ospIds: data.ospIds ? data.ospIds.map(String) : [],
      };

      console.log("Submitting Payload:", updatePayload);

      // Call API with the formatted data
      const enterpriseSaveResponse = await callApiWithOptions({
        endpoint: `bcid/enterprise/save`,
        method: HttpMethod.POST,
        body: updatePayload, // Ensure JSON format
      });
      loadData();
      console.log("Enterprise updated successfully:", enterpriseSaveResponse);
    } catch (error) {
      console.error("Failed to update enterprise:", error);
    } finally {
      setSaveInProgress(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <p>
          Please fill out the enterprise information below. Fields marked with
          an asterisk (*) are required. This information will be used manage and
          verify your Buisness Caller Id.
        </p>

        <div className="card">
          <div className="card-header">
            <i className="bi bi-building me-2"></i>
            Legal Information
            <span
              className={`badge rounded-pill ms-2 ${
                enterpriseData?.dto.status === 0
                  ? "bg-primary text-light"
                  : enterpriseData?.dto.status === 1
                    ? "bg-success text-light"
                    : "bg-danger text-light"
              }`}
            >
              {enterpriseData?.dto.statusName}
            </span>
          </div>
          <div className="card-body">
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="legalName" className="form-label">
                  Legal Name*
                </label>
                <input
                  id="legalName"
                  type="text"
                  className={`form-control ${errors.legalName ? "is-invalid" : ""}`}
                  {...register("legalName", {
                    required: "Legal name is required",
                  })}
                />
                {errors.legalName && (
                  <div className="invalid-feedback">
                    {errors.legalName.message}
                  </div>
                )}
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="ein" className="form-label">
                  EIN*
                </label>
                <Controller
                  name="ein"
                  control={control}
                  rules={{
                    required: "EIN is required",
                    pattern: {
                      value: /^\d{2}-\d{7}$/,
                      message: "EIN must be in format XX-XXXXXXX",
                    },
                    validate: (value) =>
                      value.trim() !== "" || "EIN cannot be empty",
                  }}
                  render={({ field }) => (
                    <input
                      id="ein"
                      type="text"
                      className={`form-control ${errors.ein ? "is-invalid" : ""}`}
                      maxLength={10}
                      value={field.value || ""}
                      onChange={(e) => field.onChange(e.target.value)}
                      onBlur={(e) => field.onChange(formatEIN(e.target.value))} // Format onBlur
                      placeholder="XX-XXXXXXX"
                    />
                  )}
                />
                {errors.ein && (
                  <div className="invalid-feedback">{errors.ein.message}</div>
                )}
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="dba" className="form-label">
                  DBA (Doing Business As)
                </label>
                <input
                  id="dba"
                  type="text"
                  className="form-control"
                  {...register("dba")}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="dunsNumber" className="form-label">
                  DUNS Number
                </label>
                <input
                  id="dunsNumber"
                  type="text"
                  className={`form-control ${errors.dunsNumber ? "is-invalid" : ""}`}
                  {...register("dunsNumber", {
                    pattern: {
                      value: /^\d{9}$/,
                      message: "DUNS number must be 9 digits",
                    },
                    validate: (value) =>
                      value.trim() === "" ||
                      /^\d{9}$/.test(value) ||
                      "DUNS must be 9 digits",
                  })}
                />
                {errors.dunsNumber && (
                  <div className="invalid-feedback">
                    {errors.dunsNumber.message}
                  </div>
                )}
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="orgType" className="form-label">
                  Organization Type*
                </label>
                <select
                  id="orgType"
                  className={`form-select ${errors.orgType ? "is-invalid" : ""}`}
                  {...register("orgType", {
                    required: "Organization type is required",
                  })}
                >
                  <option value="">--Select Organization Type--</option>
                  <option value="A">Commercial Enterprise</option>
                  <option value="B">Government/Public Service</option>
                  <option value="C">Public Charity/Non-profit</option>
                </select>
                {errors.orgType && (
                  <div className="invalid-feedback">
                    {errors.orgType.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header">
            <i className="bi bi-geo-alt me-2"></i>
            Address Information
          </div>
          <div className="card-body">
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="streetAddress" className="form-label">
                  Street Address
                </label>
                <input
                  id="streetAddress"
                  type="text"
                  className="form-control"
                  {...register("streetAddress")}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="city" className="form-label">
                  City
                </label>
                <input
                  id="city"
                  type="text"
                  className="form-control"
                  {...register("city")}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="state" className="form-label">
                  State / Province*
                </label>
                <select
                  id="state"
                  className={`form-select ${errors.state ? "is-invalid" : ""}`}
                  {...register("state", { required: "State is required" })}
                >
                  <option value="">--Select State/Province--</option>
                  {NORTH_AMERICAN_REGIONS.slice(1).map((region) => (
                    <option key={region.value} value={region.value}>
                      {region.label}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <div className="invalid-feedback">{errors.state.message}</div>
                )}
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="zip" className="form-label">
                  ZIP Code
                </label>
                <input
                  id="zip"
                  type="text"
                  className={`form-control ${errors.zip ? "is-invalid" : ""}`}
                  {...register("zip", {
                    pattern: {
                      value: /^\d{5}(-\d{4})?$/,
                      message: "Invalid ZIP code format",
                    },
                  })}
                />
                {errors.zip && (
                  <div className="invalid-feedback">{errors.zip.message}</div>
                )}
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="countryCode" className="form-label">
                  Country*
                </label>
                <select
                  id="countryCode"
                  className={`form-select ${errors.countryCode ? "is-invalid" : ""}`}
                  {...register("countryCode", {
                    required: "Country is required",
                  })}
                >
                  {COUNTRIES.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
                {errors.countryCode && (
                  <div className="invalid-feedback">
                    {errors.countryCode.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header">
            <i className="bi bi-briefcase me-2"></i>
            Business Information
          </div>
          <div className="card-body">
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="industryId" className="form-label">
                  Industry
                </label>
                <Controller
                  name="industryId"
                  control={control}
                  render={({ field }) => (
                    <select
                      id="industryId"
                      className={`form-select ${errors.industryId ? "is-invalid" : ""}`}
                      value={field.value || ""}
                      onChange={(e) => field.onChange(e.target.value || null)}
                    >
                      <option value="">--Select Industry--</option>{" "}
                      {industries.map((industry) => (
                        <option key={industry.value} value={industry.value}>
                          {industry.text}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="employeeCount" className="form-label">
                  Employee Count
                </label>
                <input
                  id="employeeCount"
                  type="number"
                  className={`form-control ${errors.employeeCount ? "is-invalid" : ""}`}
                  {...register("employeeCount", {
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: "Employee count must be positive",
                    },
                  })}
                />
                {errors.employeeCount && (
                  <div className="invalid-feedback">
                    {errors.employeeCount.message}
                  </div>
                )}
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="callsPerMonth" className="form-label">
                  Calls Per Month
                </label>
                <input
                  id="callsPerMonth"
                  type="number"
                  className={`form-control ${errors.callsPerMonth ? "is-invalid" : ""}`}
                  {...register("callsPerMonth", {
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: "Calls per month must be positive",
                    },
                  })}
                />
                {errors.callsPerMonth && (
                  <div className="invalid-feedback">
                    {errors.callsPerMonth.message}
                  </div>
                )}
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="url" className="form-label">
                  Website URL
                </label>
                <input
                  id="url"
                  type="url"
                  className={`form-control ${errors.url ? "is-invalid" : ""}`}
                  {...register("url", {
                    pattern: {
                      value: /^https?:\/\/.+/,
                      message: "Must be a valid URL",
                    },
                  })}
                />
                {errors.url && (
                  <div className="invalid-feedback">{errors.url.message}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header">
            <i className="bi bi-person me-2"></i>
            Primary Contact Information
          </div>
          <div className="card-body">
            <div className={styles.formGrid}>
              <div className={styles.formGroup}>
                <label htmlFor="primaryContactName">Contact Name</label>
                <input
                  className={`form-control`}
                  id="primaryContactName"
                  type="text"
                  {...register("primaryContactName")}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="primaryContactEmail">Contact Email</label>
                <input
                  id="primaryContactEmail"
                  className={`form-control`}
                  type="email"
                  {...register("primaryContactEmail", {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                {errors.primaryContactEmail && (
                  <span className={styles.error}>
                    {errors.primaryContactEmail.message}
                  </span>
                )}
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="primaryContactPhoneNumber">Contact Phone</label>
                <input
                  className={`form-control`}
                  id="primaryContactPhoneNumber"
                  type="tel"
                  {...register("primaryContactPhoneNumber", {
                    pattern: {
                      value: /^\+?[\d\s-]+$/,
                      message: "Invalid phone number",
                    },
                  })}
                />
                {errors.primaryContactPhoneNumber && (
                  <span className={styles.error}>
                    {errors.primaryContactPhoneNumber.message}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header">
            <i className="bi bi-telephone me-2"></i>
            Operating Service Providers
          </div>
          <div className="card-body">
            <div className={styles.formGroup}>
              <label className="form-label">Operating Service Providers*</label>
              <Controller
                name="ospIds"
                control={control}
                rules={{ required: "Please select at least one OSP" }}
                render={({ field }) => (
                  <SlSelect
                    multiple
                    value={(field.value || []).map(String)}
                    onSlChange={(e) => {
                      const target = e.target as HTMLSelectElement;
                      if (target) {
                        const selectedValues = Array.from(
                          target.selectedOptions,
                        ).map((option) => option.value);
                        field.onChange(selectedValues);
                      }
                    }}
                    className={`w-100 ${styles.ospSelect}`}
                    placeholder="--Select Operating Service Provider--"
                  >
                    {availableOSPs.map((osp) => (
                      <SlOption
                        key={osp.originatingServiceProviderId}
                        value={osp.originatingServiceProviderId}
                      >
                        {osp.ospName}
                      </SlOption>
                    ))}
                  </SlSelect>
                )}
              />

              {errors.ospIds && (
                <div className="invalid-feedback d-block">
                  {errors.ospIds.message}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3">
          <button type="submit" className="btn btn-primary">
            {!saveInProgress ? (
              "Save Changes"
            ) : (
              <SaveInProgress isVisible={saveInProgress} />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ManageEnterprise;
