import "@shoelace-style/shoelace/dist/themes/light.css";
import { SlSelect, SlOption } from "@shoelace-style/shoelace/dist/react";
import { FC } from "react";
import { callApi, HttpMethod } from "src/services/apiService";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";

const AccountsIsland: FC = () => {
  const { acctId, acctName } = useAppSelector(selectAuth);

  const handleSelectChange = async (event: CustomEvent) => {
    const slSelect = event.target as any;
    const selectedOptions = slSelect.value ? slSelect.value : [];

    try {
      const apiUrl = `sysadmin/SaveDialerUsed`;
      const method = HttpMethod.POST;
      const body = JSON.stringify({ selectedOptions, acctId });

      // This has to have async or it blocks the main thread
      // and the SaveInProgress component doesn't redner
      await callApi(apiUrl, method, body);
      console.log("Settings saved successfully");
    } catch (error) {
      console.error("Failed to save settings:", error);
    }
  };

  return (
    <div style={{ height: "80vh" }}>
      <span>
        Dialer {acctName} ({acctId}) uses:
      </span>
      <SlSelect
        multiple
        clearable
        placement="bottom"
        placeholder="Click to select customer dialer..."
        onSlChange={handleSelectChange}
      >
        <SlOption value="dialpad">Dialpad</SlOption>
        <SlOption value="five9">Five9</SlOption>
        <SlOption value="genesys">Genesys</SlOption>
        <SlOption value="ringcentral">RingCentral</SlOption>
        <SlOption value="talkdesk">Talkdesk</SlOption>
        <SlOption value="twilio-flex">Twilio Flex</SlOption>
        <SlOption value="vonage">Vonage</SlOption>
        <SlOption value="zoom-phone">Zoom Phone</SlOption>
        <SlOption value="3cx">3CX</SlOption>
        <SlOption value="avaya">Avaya</SlOption>
        <SlOption value="cisco-webex">Cisco Webex Calling</SlOption>
        <SlOption value="cloudtalk">CloudTalk</SlOption>
        <SlOption value="dixa">Dixa</SlOption>
        <SlOption value="freshcaller">Freshcaller</SlOption>
        <SlOption value="hubspot">HubSpot Calling</SlOption>
        <SlOption value="jive">Jive Communications</SlOption>
        <SlOption value="justcall">JustCall</SlOption>
        <SlOption value="microsoft-teams">Microsoft Teams</SlOption>
        <SlOption value="nextiva">Nextiva</SlOption>
        <SlOption value="nice-incontact">NICE inContact</SlOption>
        <SlOption value="other">Other</SlOption>
        {/* Add more options as needed */}
      </SlSelect>

      {/* Raymond will add controls go here along with save button */}
    </div>
  );
};

export default AccountsIsland;
