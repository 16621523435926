import { store } from "src/app/store";
import { logout } from "src/features/authSlice";
import * as Sentry from "@sentry/react";

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

interface CallApiOptions {
  endpoint: string;
  method: HttpMethod;
  body?: any;
  redirect?: boolean;
  contentType?: string;
  file?: File;
  overrideLoginId?: string;
  allowedErrorStatus?: number[];
}

// I created this wrapper so we could have an alternative cleaner
// way to callApi with an options object when we need to use
// optional parameters - JH 2025-02-06
export async function callApi(
  endpoint: string,
  method: HttpMethod,
  body?: any,
  redirect: boolean = true,
  contentType?: string,
  file?: File,
  overrideLoginId?: string,
  allowedErrorStatus?: number[],
): Promise<any> {
  return callApiWithOptions({
    endpoint,
    method,
    body,
    redirect,
    contentType,
    file,
    overrideLoginId,
    allowedErrorStatus,
  });
}

export async function callApiWithOptions(
  options: CallApiOptions,
): Promise<any> {
  const baseUrl = import.meta.env.VITE_APP_API_URL;

  const state = store.getState();
  const { acctId, loginId: stateLoginId, csrfToken } = state.auth;
  const loginId = options.overrideLoginId ?? stateLoginId;
  let requestOptions: RequestInit;

  if (options.file) {
    // Handle file upload
    const formData = new FormData();
    formData.append("file", options.file); // Append file

    if (options.body) {
      Object.keys(options.body).forEach((key) => {
        formData.append(key, options.body[key]);
      });
    }

    formData.append("acctId", acctId ?? "");
    formData.append("loginId", loginId ?? "");
    formData.append("csrfToken", csrfToken ?? "");

    requestOptions = {
      method: options.method,
      body: formData,
      credentials: "include",
    };
  } else {
    // Handle regular JSON payload
    const headers = new Headers();
    headers.append("Content-Type", options.contentType ?? "application/json");
    headers.append("acctId", acctId ?? "");
    headers.append("loginId", loginId ?? "");
    headers.append("csrfToken", csrfToken ?? "");

    requestOptions = {
      method: options.method,
      headers: headers,
      body: options.body ? JSON.stringify(options.body) : undefined,
      credentials: "include",
    };
  }

  Sentry.addBreadcrumb({
    category: "fetch",
    message: `Fetching data from ${baseUrl}/${options.endpoint}. Using ${requestOptions}`,
  });

  const response = await fetch(
    `${baseUrl}/${options.endpoint}`,
    requestOptions,
  );

  if (!response.ok) {
    // Check if this status code is allowed
    if (options.allowedErrorStatus?.includes(response.status)) {
      return response;
    }

    // If user is unauthorized, redirect to login page
    if (response.status === 401 && options.redirect) {
      // Make sure redux store knows we are logged out
      // Doing this clears all session storage and cookies
      store.dispatch(logout());
      console.log("Session is not authorized");
      // Note the redirect will unload the page so nothing below this run
      window.location.href = "/login?reason=notauthorized";
      return;
    } else {
      const errorBody = await response.json().catch(() => ({}));
      const error = new Error(
        errorBody.message || response.statusText || "An error occurred",
      );
      (error as any).status = response.status;
      (error as any).data = errorBody;
      throw error;
    }
  }

  return response.json();
}
